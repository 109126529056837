import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";

//Others
import { isObjEmpty } from "../../../Utils/IsEmptyObject/IsEmptyObject";

import {
  TextMaskCustom,
  NumberFormatCustom,
  AsyncSelectField,
} from "../../../../utils/renderField/renderField";

const CreateProductoForm = ({ onSubmit, data, nextId }) => {
  // Others
  const { initialValues } = {};
  const requerido_msg = "Este campo es requerido";

  async function submit(values) {
    let erros = { ...initialValues };

    if (!values.nombre) {
      erros.nombre = requerido_msg;
    }
    if (!values.catalogo) {
      erros.catalogo = requerido_msg;
    }
    if (!values.descripcion) {
      erros.descripcion = requerido_msg;
    }

    if (!values.letra || values.letra == null) {
      values.letra = null;
    }

    let checkErrors = isObjEmpty(erros);

    if (checkErrors) {
      onSubmit(values);
    } else {
      return erros;
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={submit}
        initialValues={{ id: nextId, ...data }} //data
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              {/* Row 1 */}
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  name="id"
                  label="Codigo"
                  fullWidth
                  variant="standard"
                  required={true}
                  InputProps={{
                    readOnly: true,
                  }} 
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  name="letra"
                  label="Letra"
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Select
                  required={true}
                  name="catalogo"
                  label="Catalogo"
                  fullWidth
                  variant="standard"
                >
                  <MenuItem value={"MINAS"}>Minas</MenuItem>
                  <MenuItem value={"DIAMANTE"}>Diamante</MenuItem>
                </Select>
              </Grid>
              {/* ------ */}

              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="precio_negro"
                  label="Precio negro"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="precio_color"
                  label="Precio color"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              {/* --------- */}

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="nombre"
                  label="Nombre"
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              {/* Row 2 */}
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="descripcion"
                  label="Descripcion"
                  fullWidth
                  multiline
                  rows={3}
                  maxRows={5}
                  variant="standard"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={12} textAlign="center">
                <Link
                  to="/productos/"
                  className="btn btn-secondary mr-3 mt-md-0"
                >
                  Volver
                </Link>
                <Button variant="contained" color="primary" type="submit">
                  Registrar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default CreateProductoForm;
{
  /*
<pre className="mt2">{JSON.stringify(values)}</pre>
*/
}
