import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useStyles, theme } from "../../../Utils/ThemeStyle/Theme";

import { useHistory } from "react-router-dom";

//Const
import CONST from "../../../../consts/const";

//Components
import CreateUsuarioForm from "./CreateUsuarioForm";
import FileUploader from "../../../Utils/FileUploader/FileUploader";

//Services
import { manageErrors } from '../../../../hooks/manageErrors'
import TrabajadorService from "../trabajador.service";
import Swal from "sweetalert2";


//Others
import LoaderContext from "../../../../context/ContextLoader";

const CreateUsuario = () => {
  //Params
  const { id } = useParams();
  let history = useHistory();
  const { loader, setLoader } = useContext(LoaderContext);

  //States
  const [imageSrc, setImageSrc] = useState(undefined);
  const [data, setData] = useState({});

  const onFileChange = (e, file, key) => {
    file = file || e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file) {
      if (!file.type.match(pattern)) {
        alert("Formato inválido");
        return;
      }
      // this.setState({ loaded: false });
      reader.onload = (e) => {
        setImageSrc({ [key]: file });
      };
      reader.readAsDataURL(file);
    }
  };

  //Submit
  const onSubmit = (dataForm) => {
    //Copy of data
    const body = { ...dataForm };
    setLoader(true);
    if (id) {
      TrabajadorService.updateTrabajadorContent(body, id)
        .then((response) => {
          if (response && response.status === 200) {
            Swal.fire("Guardado!", "", "success");
            history.push("/usuarios");
          }
        })
        .catch((e) => {
          manageErrors(e);
        }).finally(()=>{
          setLoader(false);
        });
    } else {
      TrabajadorService.createTrabajadorContent(body)
        .then((response) => {
          if (response && response.status === 200) {
            Swal.fire("Guardado!", "", "success");
            history.push("/usuarios");
          }
        })
        .catch((e) => {
          manageErrors(e);
        }).finally(()=>{
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      TrabajadorService.getItem(id)
        .then((response) => {

          delete response.data.password;
          setData(response.data);
        })
        .catch((e) => {
          manageErrors(e);
        });
    }

    return () => {
      // removerItem();
    };
  }, []);

  return (
    <Container maxWidth="md">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Form
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          data={data}
          onSubmit={onSubmit}
          onFileChange={onFileChange}
          id={id}
        />
      </ThemeProvider>
    </Container>
  );
};

const Form = ({ imageSrc, setImageSrc, data, onSubmit, onFileChange, id }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center">
            Nuevo Usuario
          </Typography>

          <CreateUsuarioForm id={id} data={data} onSubmit={onSubmit} />
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default CreateUsuario;
