import React, { useState, useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useStyles, theme } from "../../../../Utils/ThemeStyle/Theme";

import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";

//Components
import CreateAsignacionForm from "./CreateAsignacionForm";
import TableCabecilla from "./Table";
import AsignacionTrabajadorForm from "./AsignacionTrabajadorForm";

//Others
import LoaderContext from "../../../../../context/ContextLoader";
import { manageErrors } from '../../../../../hooks/manageErrors'
import Swal from "sweetalert2";

import AsignacionService from "../../asignacion.service";

const CreateAsignacionCabecilla = () => {
  const [productos, setProductos] = useState([]);
  const [productosSemi, setProductosSemi] = useState([]);
  const [ids, setId] = useState([]);
  const { loader, setLoader } = useContext(LoaderContext);

  let history = useHistory();

  const agregar = (dataForm) => {
    let productos_arr = [...productos];
    let productos_ids = [...ids];
    let productosSemi_ids = [...productosSemi];
    
    productos_arr.push(dataForm.producto);

    productos_ids.push(dataForm.producto.id);
    if(dataForm.producto.estado === 'SEMI-TERMINADO'){ 
      productosSemi_ids.push(dataForm.producto.id);
      setProductosSemi(productosSemi_ids);
    }
    setProductos(productos_arr);
    setId(productos_ids);
  };

  const quitar = (dataForm) => {
    let productos_arr = [...productos];
    let productos_ids = [...ids];
    let productosSemi_ids = [...productosSemi];

    const remove_producto = productos_arr.filter(
      (item) => item.id !== dataForm.id
    );

    const remove_id = productos_ids.filter((item) => item !== dataForm.id);
    const removeSemi_id = productosSemi_ids.filter((item) => item !== dataForm.id);

    setProductosSemi(removeSemi_id);
    setProductos(remove_producto);
    setId(remove_id);
  };

  const Submit = (dataForm) => { 
    let values = { ...dataForm };
    let prod = [...productos];
    let ids_prod = [...ids];
    let productosSemi_ids = [...productosSemi];

    if (!values.id_cabecilla) {
      Swal.fire("Error!", "Seleccione una cabecilla", "error");
      return false;
    } else if (prod.length === 0) {
      Swal.fire("Error!", "No existen productos asignados", "error");
      return false;
      //history.push("/producto");
    } else {
      let obj = {
        productos_id: ids_prod.filter(item => !productosSemi_ids.includes(item)),
        id_cabecilla: values.id_cabecilla,
        productosSemi: productosSemi_ids
      };
      setLoader(true);
      AsignacionService.createContent(obj)
        .then((response) => {
          if (response && response.status === 201) {
            Swal.fire("Guardado!", "", "success");
            history.push("/asignacion");
          }
        })
        .catch((e) => {
          manageErrors(e)
        }).finally(()=>{
          setLoader(false)
        });
    }
  };

  return (
    <Container maxWidth="md">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Form agregar={agregar} ids={ids} />
        <TableCabecilla data={[...productos]} quitar={quitar} />
        <br />
        <AsignacionTrabajadorForm onSubmit={Submit} />
      </ThemeProvider>
    </Container>
  );
};

const Form = ({ agregar, ids }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="left">
            Nueva asignacion cabecilla
          </Typography>

          <CreateAsignacionForm onSubmit={agregar} ids={ids} />
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default CreateAsignacionCabecilla;
