import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "./App.css";

import AuthService from "./services/auth.service";
import NavBar from "./components/Utils/Navbar/NavBar";
import MobileNavBar from "./components/Utils/Navbar/MobileNavBar"; // Importar el nuevo componente

import LoadMask from "./components/Utils/LoadMask/LoadMask";
import LoaderContext from "./context/ContextLoader";

import EventBus from "./common/EventBus";
import Routes from "./routes";
import Hidden from "@mui/material/Hidden"; // Importar el componente Hidden

const App = () => {
  let navigate = useHistory();
  const [mobileOpen, setMobileOpen] = useState(false); // Agregamos el estado para controlar la apertura del drawer

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen); // Cambiamos el estado cuando se llama a esta función
  };

  const user = AuthService.getCurrentUser();

  const logOut = () => {
    AuthService.logout();
    window.location.reload();
  };

  const { loader, setLoader } = useContext(LoaderContext);

  return (
    <React.Fragment>
      {user && user.token ? (
        <LoadMask loading={loader} blur>
          <Box className="mainPage" sx={{ display: "flex" }}>
            <Hidden xsDown implementation="css">
              <NavBar
                logOut={logOut}
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen} // añadir esta línea
              />
            </Hidden>
            <Hidden smUp implementation="css">
              <MobileNavBar
                logOut={logOut}
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen} // añadir esta línea
              />
            </Hidden>
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 2, mt: { xs: 6, sm: 0 } }}
            >
              <Routes />
            </Box>
          </Box>
        </LoadMask>
      ) : (
        <Routes />
      )}
    </React.Fragment>
  );
};

export default App;
