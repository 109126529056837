import React, { useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";


import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Table from "./Table";
//import FiltersAsignacion from "./Filters";

const ListaAsignacionTotalCabecillas = () => {
  const dayjs = require("dayjs");

  const [value, setValue] = useState("1");
  const [catalogo, setCatalogo] = useState("TODOS");
  const [search, setSearch] = useState("");

  const [anio, setAnio] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <h5 className="ml-3 mt-3"><strong>Lista de Productos Totales por Cabecilla</strong></h5>
      {/*<FiltersAsignacion
        search={search}
        setSearch={setSearch}
        catalogo={catalogo}
        setCatalogo={setCatalogo}
        className=""
  />*/}

      <FiltersAsignacion anio={anio} setAnio={setAnio} className="" />

      <Box sx={{ width: "100%", typography: "body1" }}>
        
      <Table value={1} search={search} catalogo={catalogo} anio={anio} />
      </Box>
    </React.Fragment>
  );
};


const FiltersAsignacion = ({ anio, setAnio }) => {
  const dayjs = require("dayjs");
  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <br />
        <Stack spacing={3}>
          <DatePicker
            views={["year"]}
            label="AñO"
            value={anio}
            onChange={(newValue) => {
              if (newValue) {
                const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                setAnio(formattedDate); 
              }
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default ListaAsignacionTotalCabecillas;
