import React, { Component } from "react";
import "./fileuploader.css";

const defaultProps = {
  baseColor: "#BFBFBF",
  activeColor: "green",
  overlayColor: "rgba(255,255,255,0.3)",
  opacity: 0,
  width: "100%",
};

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      imageSrc: "",
      loaded: false,
      added: false,
    };

    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }
  componentDidUpdate(nextProps) {
    if (
      this.state.imageSrc === "" &&
      this.props.source !== undefined &&
      this.props.source !== null
    ) {
      this.setState({ imageSrc: this.props.source, loaded: true });
    }
  }

  componentWillMount() {
    if (this.props.source !== null && this.props.source !== undefined) {
      // setea la imágen si se le envia una
      this.setState({
        imageSrc: this.props.source,
        loaded: true,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.source !== null && nextProps.source !== undefined) {
      const { imageSrc } = this.state;
      // Se valida si es diferente las imagenes que se esta mandando
      // se asimila que se cambio la imagen
      if (imageSrc !== nextProps.source) {
        this.setState({
          imageSrc: imageSrc,
          loaded: true,
        });
      } else {
        // setea la imágen si se le envia una
        this.setState({
          imageSrc: nextProps.source,
          loaded: true,
        });
      }
    } else {
      const { imageSrc, added } = this.state;
      //  Si es undefined se asimila que se esta editando
      if (nextProps.source !== undefined) {
        // Si la imagen en el estado est asignado
        // se limpia ya que no se esta mandando un source
        if (!added) {
          if (imageSrc !== "" && imageSrc !== null) {
            this.setState({
              imageSrc: "",
              loaded: false,
            });
          }
        } else {
          if (nextProps.source === null && imageSrc !== "") {
            this.setState({
              imageSrc: imageSrc,
              loaded: true,
            });
          }
        }
      }
    }
  }

  onDragEnter(e) {
    this.setState({ active: true });
  }

  onDragLeave(e) {
    this.setState({ active: false });
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDrop(e) {
    e.preventDefault();
    this.setState({ active: false });
    this.onFileChange(e, e.dataTransfer.files[0]);
  }

  onFileChange(e, file) {
    this.props.onFileChange(e, file, this.props.clave);
    file = file || e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file) {
      if (!file.type.match(pattern)) {
        alert("Formato inválido");
        return;
      }

      this.setState({ loaded: false, added: false });

      reader.onload = (e) => {
        if (!this.props.keepSource)
          this.setState({
            imageSrc: reader.result,
            loaded: true,
            added: true,
          });
      };
      reader.readAsDataURL(file);
    }
  }

  getFileObject() {
    return this.refs.input.files[0];
  }

  getFileString() {
    return this.state.imageSrc;
  }

  render() {
    let state = this.state,
      props = defaultProps,
      labelClass = `uploader ${state.loaded && "loaded"}`,
      borderColor = state.active ? props.activeColor : props.baseColor,
      widthDefault = props.width,
      iconColor = state.active
        ? props.activeColor
        : state.loaded
        ? props.overlayColor
        : props.baseColor,
      hideIcon = state.loaded ? 0 : 1;
    const { icono, frase, width, height, disabled, texto } = this.props;
    return (
      <label
        className={labelClass}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
        style={{
          outlineColor: borderColor,
          height: height ? height : "200px",
          width: width ? width : widthDefault,
        }}
      >
        {state.imageSrc === "" || state.imageSrc === null ? (
          <img
            style={{ color: iconColor, opacity: hideIcon }}
            className="icon icon-upload"
            src={require(`./cloud_upload.png`)}
            alt=""
            width="20px"
            height="100px"
          />
        ) : (
          <img
            src={state.imageSrc}
            className={state.loaded ? "loaded" : undefined}
          />
        )}
        {frase && (
          <p
            className="texto negro-dark2 text-center"
            style={{ opacity: hideIcon }}
          >
            Arrastra o has click en el icono para cargar la fotografía del{" "}
            {frase}
          </p>
        )}
        {texto && (
          <p
            className="texto negro-dark2 text-center"
            style={{ opacity: hideIcon }}
          >
            {texto}
          </p>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={this.onFileChange}
          ref="input"
          disabled={disabled}
        />
        {disabled == true && <div className="disabled-container"></div>}
      </label>
    );
  }
}
export default FileUploader;
