import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import ReactDOM, { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";

import { DatePicker } from "mui-rff";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import InputLabel from "@mui/material/InputLabel";
import { Form, Field } from "react-final-form";

import { NumberFormatCustom } from "../../../../utils/renderField/renderField";

const dayjs = require("dayjs");
const NuevoMovimiento = ({ onSubmit }) => {
  return (
    <React.Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{ tipo: "EGRESO" }}
        validate={() => {}}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <Grid container spacing={2}>
              {/* Row 1 */}
              {/*&<Grid item xs={12} sm={12} md={6}>
                <Select
                  required={true}
                  name="tipo"
                  label="Tipo de movimiento"
                  fullWidth
                  variant="standard"
                >
                  <MenuItem value={"INGRESO"}>INGRESO</MenuItem>

                  <MenuItem value={"EGRESO"}>EGRESO</MenuItem>
                </Select>
        </Grid>*/}

              {/* Row 1 */}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="producto"
                  label="Producto"
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              {/* Row 2 */}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="monto"
                  label="Monto"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              {/* Row 3 */}

              {/* Row 4 */}

              {/* Row 5 */}

              {/* Row 6 */}

              {/* Row 7 */}

              {/* Row 9 */}
              <Grid item xs={12} sm={12} textAlign="right">
                <Button variant="contained" color="primary" type="submit">
                  Registrar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default NuevoMovimiento;
