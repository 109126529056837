import React, { useMemo, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import { Container, CircularProgress } from "@mui/material";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client'
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LoaderContext from "./context/ContextLoader";



// create App component
const Root = () => {

  const [loader, setLoader] = useState(false);
  const loaderValue = useMemo(() => ({ loader, setLoader }), [loader]);

  return (
  <BrowserRouter>
  <LoaderContext.Provider value={loaderValue}>
    <App />
  </LoaderContext.Provider>
</BrowserRouter>
  )
}

// render App component and show it on screen
createRoot(document.getElementById('root')).render(<Root />)


/*const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <LoaderContext.Provider value={loaderValue}>
      <App />
    </LoaderContext.Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();*/
