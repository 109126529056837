/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Table from "./TableCuentas";

const ListCuentas = () => {
  return (
    <React.Fragment>
      <h5 className="ml-3 mt-3"><strong>Lista de Cuentas</strong></h5>
      <br />
      <div className="d-flex justify-content-end mb-2">
        <Table />
      </div>
    </React.Fragment>
  );
};

export default ListCuentas;
