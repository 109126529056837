import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { styled } from '@mui/material/styles';

const ColoredAppBar = styled(AppBar)({
  backgroundColor: '#00adb5',
});

const MobileNavBar = ({ logOut, handleDrawerToggle, mobileOpen }) => {
  return (
    <ColoredAppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          Reino Maya
        </Typography>
        {/* <IconButton color="inherit" aria-label="logout" edge="end" onClick={logOut}>
          <ExitToAppIcon />
        </IconButton>*/}
      </Toolbar>
    </ColoredAppBar>
  );
};

export default MobileNavBar;
