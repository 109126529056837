import React, { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useStyles, theme } from "../../../../Utils/ThemeStyle/Theme";
import { useHistory, useParams } from "react-router-dom";

//Components
import CreateAsignacionInvidiualForm from "./CreateAsignacionForm";

//Services
import AsignacionService from "../../asignacion.service";

//Others
import LoaderContext from "../../../../../context/ContextLoader";
import { manageErrors } from '../../../../../hooks/manageErrors'
import Swal from "sweetalert2";

const CreateAsignacionIndividual = () => {
  let history = useHistory();

  const { loader, setLoader } = useContext(LoaderContext); 

  //Submit
  const onSubmit = (dataForm) => {
    //Copy of data
    const body = { ...dataForm };
    setLoader(true);
    AsignacionService.createContentIndividual(body)
      .then((response) => {
        if (response && response.status === 201) {
          Swal.fire("Guardado!", "", "success");
          history.push("/asignacion");
        }
      })
      .catch((e) => {
        manageErrors(e)
      }).finally(()=>{
        setLoader(false);
      });
  };

  return (
    <Container maxWidth="md">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Form onSubmit={onSubmit} />
      </ThemeProvider>
    </Container>
  );
};

const Form = ({ onSubmit }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center">
            Nueva asignacion Individual
          </Typography>

          <CreateAsignacionInvidiualForm onSubmit={onSubmit} />
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default CreateAsignacionIndividual;
