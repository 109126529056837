import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import ReactDOM, { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Form, Field } from "react-final-form";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";

import InputLabel from "@mui/material/InputLabel";
import {
  TextMaskCustom,
  AsyncSelectField,
} from "../../../../../utils/renderField/renderField";

import AsignacionService from "../../asignacion.service";
import { manageErrors } from '../../../../../hooks/manageErrors'

const CreateAsignacionForm = ({ onSubmit, ids }) => {
  const [filter, setFilter] = useState("codigo");

  const search = (search) => {
    getTrabajadores(search).then((response) => {
    });
  };

  //Search
  const getTrabajadores = (search) => {
    return AsignacionService.getSearchProductsContent(1, search, filter, true)
      .then((response) => {
        let test = [...ids];
        const { data } = response.data;
        const filteredArray = data.filter((item) => !test.includes(item.id));
        return filteredArray;
        //return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{ tipo: filter }}
        render={({ handleSubmit, reset, submitting, pristine, form }) => (
          <form
            onSubmit={async (event) => {
              await handleSubmit(event);
              getTrabajadores();
              form.reset();
            }}
            noValidate
          >
            <br />
            <Grid container spacing={3}>
              {/* Row 1 */}

              <Grid item xs={12} sm={12} md={3}>
                <InputLabel htmlFor="tipo">Tipo *</InputLabel>
                <Select
                  required={true}
                  name="tipo"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <MenuItem value={"codigo"}>Codigo</MenuItem>
                  <MenuItem value={"nombre"}>Nombre</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputLabel htmlFor="cabecilla">Producto *</InputLabel>
                <Field
                  name="producto"
                  label="Producto"
                  key={ids}
                  component={AsyncSelectField}
                  loadOptions={getTrabajadores}
                  getOptionLabel={(option) =>
                    `${option["codigo"] + " - " + option["nombre"]}`
                  } //option.nombre
                  getOptionValue={(option) => option.id}
                  isSearchable={true}
                  isClearable={true}
                  labelKey="nombre"
                  valueKey="id"
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3} textAlign="center">
                <Button
                  disabled={submitting || pristine}
                  className="mt-4"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Asignar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default CreateAsignacionForm;

{
  /*


              <br />
              <pre className="mt2">{JSON.stringify(values)}</pre>
              <Grid item xs={12} sm={12} textAlign="center">
                <Link
                  to="/asignacion/"
                  className="btn btn-secondary mr-3 mt-md-0"
                >
                  Volver
                </Link>
                <Button variant="contained" color="primary" onClick={() => {}}>
                  Registrar
                </Button>
              </Grid> */
}
