/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "./TableProducto";
import TrabajadorService from "../producto.service";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import FiltersProductos from "./Filters";

const ListProducto = () => {
  const [value, setValue] = React.useState("1");
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState("TODOS");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div>
        <h5 className="ml-3 mt-3"><strong>Lista de Producto</strong></h5>
      </div>
      <FiltersProductos
        search={search}
        setSearch={setSearch}
        status={status}
        setStatus={setStatus}
      />

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="DIAMANTE" value="1" />
              <Tab label="MINAS" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Table value={"DIAMANTE"} search={search} status={status} />
          </TabPanel>
          <TabPanel value="2">
            <Table value={"MINAS"} search={search} status={status} />
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default ListProducto;
