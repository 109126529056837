import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import Button from "@mui/material/Button";
//Icons
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
//import IconButton from "@mui/material/IconButton";
//import BorderColorIcon from "@mui/icons-material/BorderColor";

const dayjs = require("dayjs");

const TablaDetalle = ({ data = [], reset, value, anio = 2022, tipo }) => {
  const columns = [
    {
      dataField: "Informacion",
      text: "Informacion de asignacion",
      headerStyle: (colum, colIndex) => {
        return { width: "350px", textAlign: "center", backgroundColor: '#e0e0e0' };
      },
      formatter: (cell, row) => {
        const {
          estado,
          codigo,
          tipo_bordado,
          precio,
          aumento,
          created_at,
          updated_at,
        } = row;

        return (
          <React.Fragment>
            <div>
              <span className="font-weight-bold">Codigo: </span>
              {codigo ? codigo : "-----------"}
            </div>

            <div>
              <span className="font-weight-bold">Tipo de bordado: </span>
              {tipo_bordado ? tipo_bordado : "-----------"}
            </div>

            <div>
              <span className="font-weight-bold">Precio: </span>
              {precio ? ` Q.` + precio : "-----------"}
            </div>
            <div>
              <span className="font-weight-bold">Aumento: </span>
              {aumento ? ` Q.` + aumento : "-----------"}
            </div>
            <div>
              <span className="font-weight-bold">Entrega: </span>
              {created_at
                ? dayjs(created_at).format("DD/MM/YYYY")
                : "-----------"}
            </div>
            <div>
              <span className="font-weight-bold">Recibido: </span>
              {updated_at
                ? dayjs(updated_at).format("DD/MM/YYYY")
                : "-----------"}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      dataField: "descripcionProducto",
      text: "Descripcion",
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },
      formatter: (cell, row) => {
        const { nombre, descripcion, catalogo } = row;

        return (
          <React.Fragment>
            <div>
              <span className="font-weight-bold">Catalogo: </span>
              {catalogo ? catalogo : "-----------"}
            </div>
            <div>
              <span className="font-weight-bold">Nombre de producto: </span>
              {nombre ? nombre : "-----------"}
            </div>
            <div>
              <span className="font-weight-bold">Descripcion: </span>
              <br />
              {descripcion ? descripcion : "-----------"}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      dataField: "Trabajador",
      text: "Trabajador",
      headerStyle: (colum, colIndex) => {
        return { width: "240px", textAlign: "center", backgroundColor: '#e0e0e0' };
      },
      formatter: (cell, row) => {
        const { id, nombre, nom_cabecilla, nom_trabajador, tipo_bordado } = row;

        return (
          <React.Fragment>
            <div className="mb-2">
              <span className="font-weight-bold">Trabajador: </span>
              <br />
              {nom_trabajador ? nom_trabajador : "-----------"}
            </div>

            {tipo == "CABECILLAS" && (
              <div>
                <span className="font-weight-bold">Cabecilla: </span>
                <br />
                {nom_cabecilla ? nom_cabecilla : "-----------"}
              </div>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  const columns_cabecillas = [
    {
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },
      dataField: "nom_trabajador",
      text: "Trabajador",
    },
    {
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },
      dataField: "nom_cabecilla",
      text: "Cabecilla",
    },
    {
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },
      dataField: "total",
      text: "Puntos",
    },
    {
      dataField: "acciones",
      text: "Acciones",
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center", backgroundColor: '#e0e0e0' };
      },

      formatter: (cell, row) => {
        const { id } = row;

        return (
          <React.Fragment>
            <Link
              className="bas fa-plus-circle ml-3 icons-actions"
              fontSize="small"
              to={`/detalle_realizados/${id}/${anio}`}
            >
              sdfasd
              <AssignmentTurnedInIcon />
            </Link>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <BootstrapTable
          keyField="nom_trabajador"
          data={data}
          columns={value === 1 ? columns_cabecillas : columns}
        />
      </Box>
    </React.Fragment>
  );
};

export default TablaDetalle;
