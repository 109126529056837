import React from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import ListAsignacion from "./ListAsignacion";
import FiltersAsignacion from "./Filters";

const ListaAsignacion = () => {
  const [value, setValue] = React.useState("1");
  const [catalogo, setCatalogo] = React.useState("TODOS");
  const [search, setSearch] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
        <h5 className="ml-3 mt-3"><strong>Lista de Asignaciones</strong></h5>
      <FiltersAsignacion
        search={search}
        setSearch={setSearch}
        catalogo={catalogo}
        setCatalogo={setCatalogo}
        className=""
      />
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="CABECILLAS" value="1" />
              <Tab label="INDIVIDUALES" value="2" />
              <Tab label="CABECILLAS - COMPLETOS" value="3" />
              <Tab label="INDIVIDUALES - COMPLETOS" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ListAsignacion catalogo={catalogo} search={search} value={value} />
          </TabPanel>
          <TabPanel value="2">
            <ListAsignacion catalogo={catalogo} search={search} value={value} />
          </TabPanel>
          <TabPanel value="3">
            <ListAsignacion catalogo={catalogo} search={search} value={value} />
          </TabPanel>
          <TabPanel value="4">
            <ListAsignacion catalogo={catalogo} search={search} value={value} />
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default ListaAsignacion;
