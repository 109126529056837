import React from "react";
import { useParams } from "react-router-dom";

import TableDetalleRealizados from "./TableDetalleRealizados";

const DetalleRealizados = () => {
  //Params
  const { id, anio, tipo } = useParams();

  return (
    <div>
      <TableDetalleRealizados id={id} tipo={tipo} anio={anio} />
    </div>
  );
};

export default DetalleRealizados;
