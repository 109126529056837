/* eslint-disable */
import React, { Component } from "react";
import Table from '../../../Table/Table'

import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";

//Icons
import EditIcon from "@mui/icons-material/Edit";

//Services
import Swal from "sweetalert2";
import CuentasService from "../cuentas.service";
import { manageErrors } from '../../../../hooks/manageErrors'

export default class TableCuentas extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",

      page: 1,
      count: 0,
      pageSize: 10,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
    this.retrieveTutorials();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  getRequestParams(searchTitle, page, pageSize) {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveTutorials() {
    const { searchTitle, page, pageSize } = this.state;
    const params = this.getRequestParams(searchTitle, page, pageSize);

    CuentasService.getContent(page)
      .then((response) => {
        const { data } = response.data;
        const { last_page } = response.data.meta;

        this.setState({
          tutorials: data,
          count: last_page,
        });
      })
      .catch((e) => {
        manageErrors(e);
      });
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  render() {
    const {
      searchTitle,
      tutorials,
      currentTutorial,
      currentIndex,
      page,
      count,
      pageSize,
    } = this.state;

    const columns = [
      {
        headerClassName: 'header-table', 
        field: "cabecilla",
        headerName: "Cabecilla",
        width: 250,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <React.Fragment>
              {row.cabecilla ? row.cabecilla.nombre : "--------------"}
            </React.Fragment>
          );
        },
      },
      {
        headerClassName: 'header-table', 
        field: "telefono",
        headerName: "Telefono",
        width: 100,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <React.Fragment>
              {row.cabecilla ? row.cabecilla.telefono : "--------------"}
            </React.Fragment>
          );
        },
      },
      {
        headerClassName: 'header-table', 
        field: "direccion",
        headerName: "Direccion",
        width: 400,
        renderCell: (params) => {
          const { row } = params;
          return (
            <React.Fragment>
              {row.cabecilla ? row.cabecilla.direccion : "--------------"}
            </React.Fragment>
          );
        },
      },
      {
        headerClassName: 'header-table', 
        field: "estado",
        headerName: "Estado",
        width: 150,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <React.Fragment>
              {row.status == 0 ? <div>INACTIVO</div> : <div>ACTIVO</div>}
            </React.Fragment>
          );
        },
      },
      {
        headerClassName: 'header-table', 
        field: "acciones",
        headerName: "Acciones",
        width: 150,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <React.Fragment>
              <Link
                className="bas fa-plus-circle ml-3 icons-actions"
                fontSize="small"
                to={`/cuenta/${row.id}`}
              >
                <EditIcon />
              </Link>
            </React.Fragment>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="col-md-12">
          <Table
            data={tutorials}
            columns={columns}
          />

          <div className="mt-3 d-flex justify-content-end">
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
