/* eslint-disable */
import React from "react";
import { useParams, Link } from "react-router-dom";
import Table from "./TableTrabajadoresCabecillas";

import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";

import CuentasService from "./InfoCabecilla"

const ListaTrabajadoresCabecilla = () => {
  const [value, setValue] = React.useState("1");
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState("TODOS");

  //Params
  const { id } = useParams();


  return (
    <React.Fragment>
      <div>
        <h5 className="ml-3 mt-3"><strong>Lista de Trabajadores</strong></h5>
        <CuentasService />
      </div>
      <div className="d-flex justify-content-end mb-2">
        <Link to="/trabajador/create" className="btn btn-secondary">
          Nuevo
        </Link>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
            <Table value={"TRABAJADOR"} cabecilla={id} search={search} status={status} />
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default ListaTrabajadoresCabecilla;
