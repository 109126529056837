import axios from "axios";
import authHeader from "../../../services/auth-header";
import CONST from "../../../consts/const";

const API_URL = CONST.API_URL; //"http://localhost:8080/api/test/";

class TrabajadorService {
  getTrabajadorContent(page, search, cabecilla) {
    if (search) {
      return axios.get(API_URL + "users?page=" + page + "&search=" + search, {
        headers: authHeader(),
      });
    } else {
      return axios.get(API_URL + "users?page=" + page, {
        headers: authHeader(),
      });
    }
  }

  getTrabajadorContentIndividual(page, search, cabecilla) {
    if (cabecilla) {
      return axios.get(
        API_URL +
          "trabajador?page=" +
          page +
          "&search=" +
          search +
          "&individual=" +
          true,
        {
          headers: authHeader(),
        }
      );
    } else if (search) {
      return axios.get(
        API_URL +
          "trabajador?page=" +
          page +
          "&search=" +
          search +
          "&individual=" +
          true,
        {
          headers: authHeader(),
        }
      );
    } else {
      return axios.get(
        API_URL + "trabajador?page=" + page + "&individual=" + true,
        {
          headers: authHeader(),
        }
      );
    }
  }

  //Create
  createTrabajadorContent(data) {
    return axios.post(API_URL + "users", data, {
      headers: authHeader(),
    });
  }

  //Create with DPI
  createTrabajadorWithDPIContent(data, file) {
    let formData = new FormData();
    formData.append("dpi", file);
    formData.append("data", JSON.stringify(data));
    return axios.post(API_URL + "trabajador", formData, {
      headers: {
        "Content-Type": "multipart/form-data" && authHeader(),
      },
    });
  }

  //Update
  updateTrabajadorContent(data, id) {
    return axios.put(API_URL + "users/" + id, data, {
      headers: authHeader(),
    });
  }

  //Update with DPI
  updateTrabajadorWithDPIContent(data, file, id) {
    let formData = new FormData();
    formData.append("dpi", file);
    formData.append("data", JSON.stringify(data));
    return axios.put(API_URL + `trabajador/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" } && authHeader(),
    });
  }

  //Get Item
  getItem(id) {
    return axios.get(API_URL + "users/" + id, {
      headers: authHeader(),
    });
  }

  //Delete
  delete(id) {
    return axios.delete(API_URL + "trabajador/" + id, {
      headers: authHeader(),
    });
  }
  //searchTrabajadorContent(page) {
  //  return axios.get(API_URL + "trabajador?page=" + page, {
  //    headers: authHeader(),
  //  });
  //}
}

export default new TrabajadorService();
