/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Table from "./TableTrabajador";
import TrabajadorService from "../trabajador.service";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";




const ListTrabajador = () => {
  const [value, setValue] = React.useState("1");
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState("TODOS");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div>
        <h5 className="ml-3 mt-3"><strong>Lista de Trabajadores</strong></h5>
      </div>
      <div className="d-flex justify-content-end mb-2">
        <Link to="/trabajador/create" className="btn btn-secondary">
          Nuevo
        </Link>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="CABECILLA" value="1" />
              <Tab label="INDIVIDUAL" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Table value={"CABECILLA"} search={search} status={status} />
          </TabPanel>
          <TabPanel value="2">
            <Table value={"INDIVIDUAL"} search={search} status={status} />
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default ListTrabajador;
