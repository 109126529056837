import React, { useContext, useState } from "react";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";
import { useHistory, useParams } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isObjEmpty } from "../../../../Utils/IsEmptyObject/IsEmptyObject";
import { Checkboxes, CheckboxData } from "mui-rff";

// LOADER
import LoadMask from "../../../../Utils/LoadMask/LoadMask";
import LoaderContext from "../../../../../context/ContextLoader";

import { DatePicker } from "mui-rff";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import InputLabel from "@mui/material/InputLabel";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

import {
  TextMaskCustom,
  NumberFormatCustom,
  AsyncSelectField,
} from "../../../../../utils/renderField/renderField";

//Services
import TrabajadorService from "../../../Trabajador/trabajador.service";
import AsignacionService from "../../asignacion.service";

//Others
import Swal from "sweetalert2";
import { manageErrors } from "../../../../../hooks/manageErrors";

const dayjs = require("dayjs");
const FormAsignacion = ({ data = [], handleClose, reset, catalogo = "" }) => {
  let history = useHistory();
  let idCabecilla = null;

  if (data.cabecilla_id) idCabecilla = data.cabecilla_id;

  const { loader, setLoader } = useContext(LoaderContext);
  const [nuevo, setNuevo] = useState(false);

  const onSubmit = (dataValues) => {
    let values = { ...dataValues };
    setLoader(true);

    AsignacionService.createAsignacion(values)
      .then((response) => {
        if (response && response.status === 201) {
          Swal.fire("Guardado!", "", "success");
          //history.push("/asignacion");
          handleClose();
          reset();
        }
      })
      .catch((e) => {
        manageErrors(e);
        //Swal.fire("Guardado!", "", "success");
      })
      .finally(() => {
        setLoader(false);
      });
    // }
  };

  //Search
  const getTrabajadoresCabecilla = (search) => {
    //{data && data.cabecilla_id &&}
    if (!idCabecilla) return [];
    return AsignacionService.getTrabajadorContentAsignacionCabecilla(
      1,
      search,
      true,
      idCabecilla
    )
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  //Search
  const getCabecillas = (search) => {
    return TrabajadorService.getTrabajadorContent(1, search, true)
      .then((response) => {
        const { data } = response.data;
        const filteredArray = data.filter(
          (item) => item.id !== Number(data.cabecilla_id)
        );
        return filteredArray;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  //Search
  const getTrabajadores = (search) => {
    return TrabajadorService.getTrabajadorContentIndividual(1, search, true)
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  const validate = (dataValues) => {
    const { initialValues } = {};
    const requerido_msg = "Este campo es requerido";

    let erros = { ...initialValues };

    let values = { ...dataValues };
    values.fecha = dayjs(values.fecha).format("YYYY-MM-DD");

    if (!values.aumento || values.aumento == null) values.aumento = 0;

    if (!values.tipo_bordado) {
      erros.tipo_bordado = requerido_msg;
    }

    if (nuevo) {
      values.trabajador_nuevo = true;

      if (catalogo === "INDIVIDUALES") {
        values.id_cabecilla = null;
        values.tipo = "INDIVIDUAL";
      } else {
        if (!values.id_cabecilla) {
          erros.referencia = requerido_msg;
        }
        if (values.id_cabecilla.id) {
          values.id_cabecilla = values.id_cabecilla.id;
        }
        //values.id_cabecilla = data.cabecilla.id;
        values.tipo = "TRABAJADOR";
      }

      if (!values.referencia) {
        erros.referencia = requerido_msg;
      }

      if (!values.telefono) {
        erros.telefono = requerido_msg;
      }

      if (!values.nombre) {
        erros.nombre = requerido_msg;
      }
      if (!values.id_cabecilla) {
        erros.id_cabecilla = requerido_msg;
      }

      //values.id_cabecilla = data.cabecilla.id;
    } else {
      delete values.trabajador_nuevo;

      if (!values.id_trabajador) {
        erros.id_trabajador = requerido_msg;
      }

      if (catalogo !== "INDIVIDUALES") {
        if (!values.id_cabecilla) {
          erros.id_cabecilla = requerido_msg;
        }
      }

      values.trabajador_id = values.id_trabajador.id;
      delete values.id_trabajador;
    }
    if (catalogo !== "INDIVIDUALES") {
      if (values.id_cabecilla.id) {
        values.id_cabecilla = values.id_cabecilla.id;
      }
    }
    values.salida_id = data.id;

    let checkErrors = isObjEmpty(erros);

    if (checkErrors) {
      onSubmit(values);
    } else {
      //console.log(erros);
      return erros;
    }

    //onSubmit(values);
  };

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#6c757d",
        contrastText: "#fff",
      },
    },
  });


  return (
    <React.Fragment>
      <LoadMask loading={loader} blur>
        <Form
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          onSubmit={validate}
          initialValues={{
            fecha: dayjs(new Date(), "DD-MM-YYYY"),
            aumento: 0,
            id_producto: data.producto.id,
            id_cabecilla: data.cabecilla,
          }}
          validate={() => {}}
          render={({ handleSubmit, submitting, values, form }) => {
            window.form = form;
            //if (!window.setFormValue)
            //  window.setFormValue = form.mutators.setValue;
            return (
              <form onSubmit={handleSubmit} className="mt-4" noValidate>
                <Grid container spacing={2}>
                  {/* Row 1 */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="font-weight-bold"
                  >
                    <div align={"left"}>Producto: {data.producto.codigo}</div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="font-weight-bold"
                  >
                    <div align={"right"}>
                      Fecha: {dayjs(values.fecha).format("YYYY-MM-DD")}
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <ThemeProvider theme={theme}>
                      {!nuevo && (
                        <Button
                          color="neutral"
                          variant="contained"
                          onClick={() => {
                            setNuevo(true);
                          }}
                        >
                          Nuevo Trabajador aaa
                        </Button>
                      )}

                      {nuevo && (
                        <Button
                          color="neutral"
                          variant="contained"
                          onClick={() => {
                            setNuevo(false);
                          }}
                        >
                          Buscar Trabajador
                        </Button>
                      )}
                    </ThemeProvider>
                  </Grid>

                  {nuevo && catalogo !== "INDIVIDUALES" && (
                    <React.Fragment>
                      {/* Row 2 */}
                      <Grid item xs={12} sm={12} md={12}>
                        <InputLabel htmlFor="cabecilla">Cabecilla *</InputLabel>
                        <Field
                          name="id_cabecilla"
                          label="Cabecilla"
                          component={AsyncSelectField}
                          loadOptions={getCabecillas}
                          getOptionLabel={(option) => option.nombre}
                          getOptionValue={(option) => option.id}
                          isSearchable={true}
                          isClearable={true}
                          labelKey="nombre"
                          valueKey="id"
                          //disabled={values.tipo === "TRABAJADOR" ? false : true}
                          //required={values.tipo === "TRABAJADOR" ? true : false}
                        />
                        <OnChange name="id_cabecilla">
                          {(value, previous) => {
                            // values.id_cabecilla = null;
                            // values.id_trabajador = null;
                            if (value) {
                              values.id_cabecilla = value;
                              idCabecilla = values?.id_cabecilla["id"];
                            } else {
                              idCabecilla = null;
                              //  //console.log('No existe :c', values.id_cabecilla);
                            }
                            window.form.mutators.setValue(
                              "id_trabajador",
                              null
                            );
                            //AsyncSelectField();
                          }}
                        </OnChange>
                      </Grid>
                    </React.Fragment>
                  )}

                  {!nuevo && catalogo !== "INDIVIDUALES" && (
                    <React.Fragment>
                      {/* Row 2 */}
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel htmlFor="cabecilla">Cabecilla *</InputLabel>
                        <Field
                          name="id_cabecilla"
                          label="Cabecilla"
                          component={AsyncSelectField}
                          loadOptions={getCabecillas}
                          getOptionLabel={(option) => option.nombre}
                          getOptionValue={(option) => option.id}
                          isSearchable={true}
                          isClearable={true}
                          labelKey="nombre"
                          valueKey="id"
                          //disabled={values.tipo === "TRABAJADOR" ? false : true}
                          //required={values.tipo === "TRABAJADOR" ? true : false}
                        />
                        <OnChange name="id_cabecilla">
                          {(value, previous) => {
                            // values.id_cabecilla = null;
                            // values.id_trabajador = null;
                            if (value) {
                              values.id_cabecilla = value;
                              idCabecilla = values?.id_cabecilla["id"];
                            } else {
                              idCabecilla = null;
                              //  //console.log('No existe :c', values.id_cabecilla);
                            }
                            window.form.mutators.setValue(
                              "id_trabajador",
                              null
                            );
                            //AsyncSelectField();
                          }}
                        </OnChange>
                      </Grid>
                      {!nuevo && values.id_cabecilla && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          key={`key_idCabRest${idCabecilla}`}
                        >
                          <InputLabel
                            //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                            htmlFor="cabecilla"
                          >
                            Trabajador Individual *
                          </InputLabel>
                          <Field
                            key={
                              `key_idCab${values.id_trabajador}` ||
                              `key_idCab${values.id_cabecilla}`
                            }
                            name="id_trabajador"
                            label="Trabajador"
                            component={AsyncSelectField}
                            loadOptions={getTrabajadoresCabecilla}
                            getOptionLabel={(option) => option.nombre}
                            getOptionValue={(option) => option.id}
                            isSearchable={true}
                            isClearable={true}
                            labelKey="nombre"
                            valueKey="id"
                            required={true}
                            disabled={values.id_cabecilla ? false : true}
                          />
                        </Grid>
                      )}
                      {!nuevo && !values?.id_cabecilla && (
                        <Grid item xs={12} sm={12} md={6}>
                          <InputLabel
                            //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                            htmlFor="cabecilla"
                          >
                            Trabajador Individual *
                          </InputLabel>
                          <Field
                            name="id_trabajador"
                            label="Trabajador"
                            component={AsyncSelectField}
                            loadOptions={null}
                            isSearchable={true}
                            isClearable={true}
                            labelKey="nombre"
                            valueKey="id"
                            required={true}
                            disabled={true}
                          />
                        </Grid>
                      )}
                    </React.Fragment>
                  )}

                  {!nuevo && catalogo === "INDIVIDUALES" && (
                    <React.Fragment>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel
                          //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                          htmlFor="cabecilla"
                        >
                          Trabajador Individual *
                        </InputLabel>
                        <Field
                          name="id_trabajador"
                          label="Trabajador"
                          component={AsyncSelectField}
                          loadOptions={getTrabajadores}
                          getOptionLabel={(option) => option.nombre}
                          getOptionValue={(option) => option.id}
                          isSearchable={true}
                          isClearable={true}
                          labelKey="nombre"
                          valueKey="id"
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}></Grid>
                    </React.Fragment>
                  )}

                  {nuevo && (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="nombre"
                        label="Nombre"
                        fullWidth
                        variant="standard"
                        required={true}
                      />
                    </Grid>
                  )}

                  {nuevo && (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="telefono"
                        label="Telefono"
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        required={true}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                    </Grid>
                  )}

                  {/*JSON.stringify(data.cabecilla)*/}

                  {nuevo && (
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required={true}
                        name="referencia"
                        label="Referencia de direccion"
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={5}
                        variant="standard"
                        autoComplete="shipping address-line1"
                      />
                    </Grid>
                  )}

                  {/* Row 3 */}

                  {/* Row 4 */}

                  <Grid item xs={12} sm={12} md={4}>
                    {data &&
                      data.asignacion &&
                      data.asignacion.length === 1 &&
                      data.asignacion[0].tipo_bordado === "NEGRO" && (
                        <React.Fragment>
                          <Select
                            required={true}
                            name="tipo_bordado"
                            label="Tipo de bordado"
                            fullWidth
                            variant="standard"
                          >
                            <MenuItem value={"COLOR"}>Color</MenuItem>
                          </Select>
                        </React.Fragment>
                      )}

                    {data &&
                      data.asignacion &&
                      data.asignacion.length === 0 && (
                        <React.Fragment>
                          <Select
                            required={true}
                            name="tipo_bordado"
                            label="Tipo de bordado"
                            fullWidth
                            variant="standard"
                          >
                            <MenuItem value={"NEGRO"}>Negro</MenuItem>
                            {/*<MenuItem value={"COLOR"}>Color</MenuItem>*/}
                            <MenuItem value={"COMPLETO"}>Completo</MenuItem>
                          </Select>
                        </React.Fragment>
                      )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      name="precio"
                      label="Precio"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        readOnly: true,
                      }}
                      value={
                        data.producto
                          ? values.tipo_bordado === "COMPLETO"
                            ? Number(
                                data.producto.precio_negro +
                                  data.producto.precio_color
                              )
                            : values.tipo_bordado === "NEGRO"
                            ? data.producto.precio_negro
                            : values.tipo_bordado === "COLOR"
                            ? data.producto.precio_color
                            : 0
                          : 0
                      }
                      fullWidth
                      variant="standard"
                      required={true}
                    />
                  </Grid>

                  {/* Row 5 */}
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      name="aumento"
                      label="Aumento"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      fullWidth
                      variant="standard"
                      required={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} textAlign="center">
                    <Button variant="contained" color="primary" type="submit">
                      Registrar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </LoadMask>
    </React.Fragment>
  );
};

export default FormAsignacion;
