import axios from "axios";
import { api } from "../utils/api";
import CONST from "../consts/const";

class AuthService {
  login(data) {

    return axios.post(CONST.API_URL + "auth", data).then((response) => {
      if (response.data.token) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
  }

  logout() {
    sessionStorage.removeItem("user");
    return true
  }

  register(username, email, password) {
    return axios.post(CONST.API_URL + "signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem("user"));
  }

  getCurrentRole() {
    let user = JSON.parse(sessionStorage.getItem("user"));

    if (user && user["user"] && user["user"]["role"]) {
      return user["user"]["role"];
    } else return null;
  }

  getCurrentName() {
    let user = JSON.parse(sessionStorage.getItem("user"));

    if (user && user["user"] && user["user"]["role"]) {
      return user["user"]["role"];
    } else return null;
  }
}

export default new AuthService();
