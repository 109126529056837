import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//import { BrowserRouter } from "react-router-dom";
//import { GuardProvider, GuardedRoute } from "react-router-guards";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";

//Trabajador
import { ListTrabajador } from "./components/view/Trabajador";
import { CreateTrabajador } from "./components/view/Trabajador";
import { ListaTrabajadoresCabecilla } from "./components/view/Trabajador"

//Producto
import { ListProducto } from "./components/view/Producto";
import { CreateProducto } from "./components/view/Producto";

//Asignacion
import {
  ListaAsignacion,
  CreateAsignacionCabecilla,
  CreateAsignacionIndividual,
} from "./components/view/Asignacion";

//Usuarios
import { CreateUsuario, ListUsuario } from "./components/view/Usuarios";

//Informes

//Asignacion
import { ListaVencidos } from "./components/view/Vencidos";
import {
  ListaRealizados,
  DetalleRealizados,
} from "./components/view/Realizados";

import ListaAsignacionTotalCabecillas from "./components/view/ListaTotalProdCabecilla/TotalRealizadosCab";

import { ListaPuntuacion } from "./components/view/Puntuacion";

import { ListCuentas, Movimientos } from "./components/view/Cuentas";

import GuardedRoute from "./security/GuardedRoute";
import GuardedPublicRoute from "./security/GuardedPublicRoute";

import AuthService from "./services/auth.service";

import ListaReportes from './components/view/Reportes/index'

const Routes = () => {
  const currentUser = AuthService.getCurrentUser();

  return (
    <React.Fragment>
      <Switch>
        {/*<Route exact path={["/", "/home"]} component={Login} />*/}

        <GuardedRoute exact path="/" auth={currentUser}>
          <Redirect to="/asignacion" />
        </GuardedRoute>

        <GuardedPublicRoute
          path="/login"
          component={Login}
          auth={currentUser}
        />
        {/*<Route exact path="/register" component={Register} />*/}
        {/*<Route exact path="/profile" component={Profile} />*/}
        {/*<Route path="/user" component={BoardUser} />*/}

        {/* User */}
        <GuardedRoute path="/profile" component={Profile} auth={currentUser} />

        {/* Usuarios */}

        <GuardedRoute
          path="/usuarios"
          component={ListUsuario}
          auth={currentUser}
        />

        <GuardedRoute
          path="/usuario/create"
          component={CreateUsuario}
          auth={currentUser}
        />

        <GuardedRoute
          path="/usuario/edit/:id"
          component={CreateUsuario}
          auth={currentUser}
        />

        {/* Trabajador */}
        <GuardedRoute
          path="/trabajadores"
          component={ListTrabajador}
          auth={currentUser}
        />

        <GuardedRoute
          path="/trabajadores_cabecilla/:id"
          component={ListaTrabajadoresCabecilla}
          auth={currentUser}
        />

        <GuardedRoute
          path="/trabajador/create"
          component={CreateTrabajador}
          auth={currentUser}
        />

        <GuardedRoute
          path="/trabajador/edit/:id"
          component={CreateTrabajador}
          auth={currentUser}
        />

        {/* Producto */}

        <GuardedRoute
          path="/productos"
          component={ListProducto}
          auth={currentUser}
        />

        <GuardedRoute
          path="/producto/create"
          component={CreateProducto}
          auth={currentUser}
        />

        <GuardedRoute
          path="/producto/edit/:id"
          component={CreateProducto}
          auth={currentUser}
        />

        {/* Asignacion */}
        <GuardedRoute
          path="/asignacion"
          component={ListaAsignacion}
          auth={currentUser}
        />

        <GuardedRoute
          path="/asignacion_individual/create"
          component={CreateAsignacionIndividual}
          auth={currentUser}
        />

        <GuardedRoute
          path="/asignacion_cabecilla/create"
          component={CreateAsignacionCabecilla}
          auth={currentUser}
        />

        {/* Cuentas */}
        <GuardedRoute
          path="/cuentas"
          component={ListCuentas}
          auth={currentUser}
        />

        <GuardedRoute
          path="/cuenta/:id"
          component={Movimientos}
          auth={currentUser}
        />

        {/* Informes */}
        {/* Vencidos */}

        <GuardedRoute
          path="/vencidos"
          component={ListaVencidos}
          auth={currentUser}
        />

        <GuardedRoute
          path="/realizados"
          component={ListaRealizados}
          auth={currentUser}
        />

        <GuardedRoute
          path="/puntuacion"
          component={ListaPuntuacion}
          auth={currentUser}
        />

        <GuardedRoute
          path="/cabecillasTotal"
          component={ListaAsignacionTotalCabecillas}
          auth={currentUser}
        />

        <GuardedRoute
          path="/detalle_realizados/:tipo/:id/:anio"
          component={DetalleRealizados}
          auth={currentUser}
        />

        {/* Reportes */}

        <GuardedRoute
          path="/reportes"
          component={ListaReportes}
          auth={currentUser}
        />

        {/* NOT FOUND */}
        <Route exact path={["*"]}>
          <Redirect to="/" />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
