import axios from "axios";
import authHeader from "../../../services/auth-header";
import CONST from "../../../consts/const";

const API_URL = CONST.API_URL; //"http://localhost:8080/api/test/";

class ProductoService {
  // Next
  getNext() {
    return axios.get(API_URL + "producto?next=" + true, {
      headers: authHeader(),
    });
  }

  get(page, catalogo, status = "", search = "") {
    return axios.get(
      API_URL +
        "producto?page=" +
        page +
        "&catalogo=" +
        catalogo +
        "&estado=" +
        status +
        "&search=" +
        search,
      {
        headers: authHeader(),
      }
    );
  }

  //Get Item
  getItem(id) {
    return axios.get(API_URL + "producto/" + id, {
      headers: authHeader(),
    });
  }

  //Create
  createProductContent(data) {
    return axios.post(API_URL + "producto", data, {
      headers: authHeader(),
    });
  }

  //Create with Photo
  createProductWithPhotoContent(data, file) {
    let formData = new FormData();
    formData.append("foto", file);
    formData.append("data", JSON.stringify(data));
    return axios.post(API_URL + "producto", formData, {
      headers: {
        "Content-Type": "multipart/form-data" && authHeader(),
      },
    });
  }

  //Delete
  delete(id) {
    return axios.delete(API_URL + "producto/" + id, {
      headers: authHeader(),
    });
  }

  //Update
  updateProductContent(data, id) {
    return axios.put(API_URL + "producto/" + id, data, {
      headers: authHeader(),
    });
  }

  //Update with DPI
  updateproductoWitPhotoContent(data, file, id) {
    let formData = new FormData();
    formData.append("foto", file);
    formData.append("data", JSON.stringify(data));
    return axios.put(API_URL + `producto/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" } && authHeader(),
    });
  }
}

export default new ProductoService();
