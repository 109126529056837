import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

import NuevoMovimiento from "./NuevoMovimiento";
import NuevoIngreso from "./NuevoIngreso";

import InfoCabecilla from "./InfoCabecilla";
import TablaMovimientos from "./TablaMovimientos";

import cuentasService from "../cuentas.service";
import { useHistory, useParams } from "react-router-dom";


// FORMS
import LoaderContext from "../../../../context/ContextLoader";
import { manageErrors } from '../../../../hooks/manageErrors'
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zindex: 100,
};

const Movimientos = () => {
  //Params
  const { id } = useParams();
  let history = useHistory();
  const { loader, setLoader } = useContext(LoaderContext);

  //States
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // -----

  const [openIngreso, setOpenIngreso] = useState(false);
  const handleOpenIngreso = () => setOpenIngreso(true);
  const handleCloseIngreso = () => setOpenIngreso(false);

  // -------
  const [actualizar, setActualizar] = useState(false);

  const onSubmit = (values) => {
    let dataForm = { ...values };

    dataForm.id_cuenta = Number(id);
    delete dataForm.tipo_movimiento;
    
    setLoader(true)
    cuentasService
      .createMovimiento(dataForm)
      .then((response) => {
        if (response && response.status === 201) {
          Swal.fire("Guardado!", "", "success");
          // history.push("/cuentas");
          setOpen(false);
          setOpenIngreso(false);
          setActualizar(true);
        }
      })
      .catch((e) => {
        manageErrors(e)
        // if (e.response.data && e.response.data.error) {
        //   Swal.fire("Error!", e.response.data.error, "error");
        // }
      }).finally(()=>{
        setLoader(false)
      });
  };

  return (
    <React.Fragment>
      <InfoCabecilla />

      {/* New */}

      <div className="d-flex justify-content-end mb-3">
        <Button onClick={handleOpenIngreso} className="mr-3" variant="outlined">
          Ingreso
        </Button>
        <Button onClick={handleOpen} className="mr-3" variant="outlined">
          Movimiento
        </Button>
      </div>

      <TablaMovimientos
        id={id}
        actualizar={actualizar}
        setActualizar={setActualizar}
      />

      {/* Nuevo */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Movimiento
          </Typography>
          <NuevoMovimiento onSubmit={onSubmit} />
        </Box>
      </Modal>

      {/* Nuevo Ingreso */}
      <Modal
        open={openIngreso}
        onClose={handleCloseIngreso}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Ingreso
          </Typography>
          <NuevoIngreso onSubmit={onSubmit} />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Movimientos;
