import axios from "axios";
import authHeader from "../../../services/auth-header";
import CONST from "../../../consts/const";

const API_URL = CONST.API_URL; //"http://localhost:8080/api/test/";

class VencidosService {
  //List
  getContent(page, pag, catalogo, search = "") {
    return axios.get(
      API_URL +
        "informe_vencidos?page=" +
        page +
        "&tipo=" +
        pag +
        "&catalogo=" +
        "TODOS" +
        "&search=" +
        "",
      {
        headers: authHeader(),
      }
    );
  }

  //Search Products
  getSearchProductsContent(page, search = "", filter) {
    return axios.get(
      API_URL +
        "producto?page=" +
        page +
        "&search=" +
        search +
        "&filter=" +
        filter +
        "&asignacion=" +
        true,
      {
        headers: authHeader(),
      }
    );
  }

  //Create
  createContent(data) {
    return axios.post(API_URL + "salida", data, {
      headers: authHeader(),
    });
  }

  createContentIndividual(data) {
    return axios.post(API_URL + "salida" + "?individual=" + true, data, {
      headers: authHeader(),
    });
  }

  createAsignacion(data) {
    return axios.post(API_URL + "asignacion", data, {
      headers: authHeader(),
    });
  }

  createRecibo(data) {
    return axios.post(API_URL + "recibo", data, {
      headers: authHeader(),
    });
  }

  updateAsignacion(data, id) {
    return axios.put(API_URL + "asignacion/" + id, data, {
      headers: authHeader(),
    });
  }

  getTrabajadorContentAsignacionCabecilla(
    page,
    search,
    cabecilla,
    id_cabecilla
  ) {
    if (cabecilla) {
      return axios.get(
        API_URL +
          "trabajador?page=" +
          page +
          "&search=" +
          search +
          "&asignacion_cabecilla=" +
          true +
          "&id_cabecilla=" +
          id_cabecilla,
        {
          headers: authHeader(),
        }
      );
    } else if (search) {
      return axios.get(
        API_URL +
          "trabajador?page=" +
          page +
          "&search=" +
          search +
          "&asignacion_cabecilla=" +
          true +
          "&id_cabecilla=" +
          id_cabecilla,
        {
          headers: authHeader(),
        }
      );
    } else {
      return axios.get(
        API_URL +
          "trabajador?page=" +
          page +
          "&asignacion_cabecilla=" +
          true +
          "&id_cabecilla=" +
          id_cabecilla,
        {
          headers: authHeader(),
        }
      );
    }
  }
}

export default new VencidosService();
