/* eslint-disable */
import React, { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
import Table from "./TableAsignacion";

const ListAsignacion = ({ value, search, catalogo, anio }) => {
  //const componentRef = useRef();
  return (
    <React.Fragment>
      {/*<div>
        <ReactToPrint
          trigger={() => (
            <button className="ml-3 btn btn-secondary">Imprimir</button>
          )}
          content={() => componentRef.current}
        />


          </div>*/}
      <Table value={value} search={search} catalogo={catalogo} anio={anio} />
      {/* -------
        <ComponentToPrint ref={componentRef} />
       */}
    </React.Fragment>
  );
};

export default ListAsignacion;
