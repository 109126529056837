import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import CuentasService from "../cuentas.service";
import { manageErrors } from '../../../../hooks/manageErrors'

const InfoCabecilla = () => {
  //Params
  const { id } = useParams();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    //first
    CuentasService.getCabecilla(id)
      .then((response) => {
        const { data } = response;
        setData(data);
      })
      .catch((e) => {
        manageErrors(e);
      });
  }, []);

  return (
    <div className="mb-2">
      <div className="container">
        <h3>Movimientos</h3>
        <div className="card mb-3">
          <h5 className="card-header">Informacion Cabecilla</h5>
          <div className="card-body">
            <div>
              <span className="font-weight-bold">Nombre:</span>
              <span>{data.nombre ? data.nombre : "------------"}</span>
            </div>
            <div>
              <span className="font-weight-bold">Apodo:</span>
              <span>{data.apodo ? data.apodo : "------------"}</span>
            </div>
            <div>
              <span className="font-weight-bold">Telefono:</span>
              <span>{data.telefono ? data.telefono : "------------"}</span>
            </div>
            <div>
              <span className="font-weight-bold">Direccion:</span>
              <span>{data.direccion ? data.direccion : "------------"}</span>
            </div>
            {/* */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCabecilla;
