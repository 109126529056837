import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Form, Field } from "react-final-form";
import InputLabel from "@mui/material/InputLabel";

import { TextField } from "mui-rff";
import { Select } from "mui-rff";

import MenuItem from "@mui/material/MenuItem";
import { isObjEmpty } from "../../../Utils/IsEmptyObject/IsEmptyObject";
import { Switches, SwitchData } from "mui-rff";
import {
  TextMaskCustom,
  AsyncSelectField,
} from "../../../../utils/renderField/renderField";

//Services
import TrabajadorService from "../trabajador.service";

const CreateUsuarioForm = ({ onSubmit, data, id }) => {
  // Others
  const { initialValues } = {};
  const requerido_msg = "Este campo es requerido";

  const switchData = [{ label: "Estado", value: true }];

  async function submit(values) {
    let erros = { ...initialValues };

    if (!values.nombre) {
      erros.nombre = requerido_msg;
    }
    //if (!values.apodo) {
    //  erros.apodo = requerido_msg;
    //}
    //if (!values.direccion) {
    //  erros.direccion = requerido_msg;
    //}
    if (!values.role) {
      erros.role = requerido_msg;
    }
    if (!values.email) {
      erros.email = requerido_msg;
    }
    if (!values.password) {
      erros.password = requerido_msg;
    }
    if (values.activo == null || values.activo == undefined) {
      erros.activo = requerido_msg;
    }

    let checkErrors = isObjEmpty(erros);

    if (checkErrors) {
      onSubmit(values);
    } else {
      return erros;
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={submit}
        initialValues={data} //data
        //validate={validate}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2} className="mt-3">
              {/* Row 0 */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="nombre"
                  label="Nombre"
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              {/* Row 1 */}
              <Grid item xs={12} sm={6}>
                <Select
                  required={true}
                  name="role"
                  label="Tipo"
                  fullWidth
                  variant="standard"
                >
                  <MenuItem value={"SECRETARY"}>SECRETARIA</MenuItem>
                  <MenuItem value={"ADMIN"}>ADMINISTRADOR</MenuItem>
                </Select>
              </Grid>

              {/* Row 2 */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              {/* Row 3 */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="password"
                  label="Contraseña"
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              <hr />

              {/* Row 4 */}
              <Grid item xs={12} sm={6}>
                <Select
                  required={true}
                  name="activo"
                  label="Estado"
                  fullWidth
                  variant="standard"
                >
                  <MenuItem value={0}>INACTIVO</MenuItem>
                  <MenuItem value={1}>ACTIVO</MenuItem>
                </Select>
              </Grid>
              {/* Row 5 */}

              <Grid item xs={12} sm={12} textAlign="center">
                <Link
                  to="/usuarios/"
                  className="btn btn-secondary mr-3 mt-md-0"
                >
                  Volver
                </Link>
                <Button variant="contained" color="primary" type="submit">
                  {id ? "Actualizar" : "Registrar"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default CreateUsuarioForm;

