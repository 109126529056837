import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useStyles, theme } from "../../../Utils/ThemeStyle/Theme";
//import UploadImage from "../../../Utils/UploadImage/UploadImage";

import { useHistory } from "react-router-dom";

//Const
import CONST from "../../../../consts/const";

//Components
import CreateTrabajadorForm from "./CreateTrabajadorForm";
import FileUploader from "../../../Utils/FileUploader/FileUploader";

// FORMS
import LoaderContext from "../../../../context/ContextLoader";
import {manageErrors} from '../../../../hooks/manageErrors'
import Swal from "sweetalert2";

//Services
import TrabajadorService from "../trabajador.service";

const CreateTrabajador = () => {
  //Params
  const { id } = useParams();

  let history = useHistory();

  //States
  const [imageSrc, setImageSrc] = useState(undefined);
  const [data, setData] = useState({});
  const { loader, setLoader } = useContext(LoaderContext);

  const onFileChange = (e, file, key) => {
    file = file || e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file) {
      if (!file.type.match(pattern)) {
        alert("Formato inválido");
        return;
      }
      // this.setState({ loaded: false });
      reader.onload = (e) => {
        setImageSrc({ [key]: file });
      };
      reader.readAsDataURL(file);
    }
  };

  //Submit
  const onSubmit = (dataForm) => {
    //Copy of data
  setLoader(true)
    const body = { ...dataForm };

    if (body.id_cabecilla) body.id_cabecilla = body.id_cabecilla.id;

    if (id) {
      if (imageSrc) {
        TrabajadorService.updateTrabajadorWithDPIContent(body, imageSrc.dpi, id)
          .then((response) => {
            if (response && response.status === 200) {
              Swal.fire("Guardado!", "", "success");
              history.push("/trabajadores");
            }
          })
          .catch((e) => {
            manageErrors(e)
          }).finally(()=> {
            setLoader(false)
          });
      } else {
        TrabajadorService.updateTrabajadorContent(body, id)
          .then((response) => {
            if (response && response.status === 200) {
              Swal.fire("Guardado!", "", "success");
              history.push("/trabajadores");
            }
          })
          .catch((e) => {
            manageErrors(e)
          }).finally(()=> {
            setLoader(false)
          });
      }
    } else {
      if (imageSrc) {
        TrabajadorService.createTrabajadorWithDPIContent(body, imageSrc.dpi)
          .then((response) => {
            if (response && response.status === 200) {
              Swal.fire("Guardado!", "", "success");
              history.push("/trabajadores");
            }
          })
          .catch((e) => {
            manageErrors(e)
          }).finally(()=> {
            setLoader(false)
          });
      } else {
        TrabajadorService.createTrabajadorContent((body))
          .then((response) => {
            if (response && response.status === 200) {
              Swal.fire("Guardado!", "", "success");
              history.push("/trabajadores");
            }
          })
          .catch((e) => {
            manageErrors(e);
          }).finally(()=> {
            setLoader(false)
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      //leer(id);
      TrabajadorService.getItem(id)
        .then((response) => {
          setData(response.data);
        })
        .catch((e) => {
          manageErrors(e);
        });
    }

    return () => {
      // removerItem();
    };
  }, []);

  return (
    <Container maxWidth="md">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Form
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          data={data}
          onSubmit={onSubmit}
          onFileChange={onFileChange}
          id={id}
        />
      </ThemeProvider>
    </Container>
  );
};

const Form = ({ imageSrc, setImageSrc, data, onSubmit, onFileChange, id }) => {
  const classes = useStyles();
  return (
    <React.Fragment>

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center">
            Nuevo Trabajador
          </Typography>

          <Grid item xs={12} sm={12} align="center" className="mb-4">
            <br />
            <FileUploader
              onFileChange={onFileChange}
              icono={"add-img"}
              frase={"trabajador."}
              clave={"dpi"}
              width="350px"
              height="200px"
              source={
                data.dpi
                  ? CONST.STATIC_URL + "uploads/trabajadores/" + data.dpi
                  : null
              }
            />
          </Grid>
          <CreateTrabajadorForm id={id} data={data} onSubmit={onSubmit} />
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default CreateTrabajador;
