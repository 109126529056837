import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";
import { useHistory, useParams } from "react-router-dom";

import { DatePicker } from "mui-rff";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import InputLabel from "@mui/material/InputLabel";
import { Form, Field } from "react-final-form";

import {
  TextMaskCustom,
  NumberFormatCustom,
  AsyncSelectField,
} from "../../../../../utils/renderField/renderField";

//Services
import TrabajadorService from "../../../Trabajador/trabajador.service";
import AsignacionService from "../../vencidos.service";

//Others
import Swal from "sweetalert2";
import AuthService from "../../../../../services/auth.service";

import { manageErrors } from '../../../../../hooks/manageErrors'
const dayjs = require("dayjs");

const FormRecibido = ({ data = [], handleClose, reset, producto }) => {
  let history = useHistory();

  const currentUser = AuthService.getCurrentUser();

  const onSubmit = (dataValues) => {
    let values = { ...dataValues };
    //values.trabajador_id = values.id_trabajador.id;
    //values.salida_id = data.id;

    values.id_receptor = currentUser.user.id;
    values.producto_id = producto.id;
    values.id_asignacion = data.id;
    if (!values.pago) values.pago = 0;

    delete values.id_trabajador;

    AsignacionService.createRecibo(values)
      .then((response) => {
        if (response && response.status == 201) {
          Swal.fire("Guardado!", "", "success");
          handleClose();
          reset();
        }
      })
      .catch((e) => {
        manageErrors(e);
        //Swal.fire("Guardado!", "", "success");
      });
    // }*/
  };

  //Search
  const getTrabajadoresCabecilla = (search) => {
    //{data && data.cabecilla_id &&}
    return AsignacionService.getTrabajadorContentAsignacionCabecilla(
      1,
      search,
      true,
      data.cabecilla_id
    )
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  //Search
  const getTrabajadores = (search) => {
    return TrabajadorService.getTrabajadorContentIndividual(1, search, true)
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  const validate = (dataValues) => {
    let values = { ...dataValues };
    values.fecha = dayjs(values.fecha).format("YYYY-MM-DD");
    onSubmit(values);
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={validate}
        initialValues={{
          fecha: dayjs(new Date(), "DD-MM-YYYY"),
          id_trabajador: data.trabajador,
          tipo_bordado: data.tipo_bordado,
          pago: 0,
        }}
        validate={() => {}}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <Grid container spacing={2}>
              {/* Row 1 */}
              {/*<Grid item xs={12} sm={12} md={6} className="font-weight-bold">
                <div align={"left"}>Producto: {data.producto.codigo}</div>
              </Grid>*/}
              <Grid item xs={12} sm={12} md={3} className="font-weight-bold">
                <div align={"left"}>Precio: Q.{data.precio}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} className="font-weight-bold">
                <div align={"right"}>Aumento: Q.{data.aumento}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="font-weight-bold">
                <div align={"right"}>
                  Fecha: {dayjs(values.fecha).format("YYYY-MM-DD")}
                </div>
              </Grid>

              {/* Row 2 */}
              <Grid item xs={12} sm={12} md={6}>
                <InputLabel
                  //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                  htmlFor="cabecilla"
                >
                  Trabajador Individual *
                </InputLabel>
                <Field
                  name="id_trabajador"
                  label="Trabajador"
                  component={AsyncSelectField}
                  loadOptions={
                    data && data.cabecilla_id
                      ? getTrabajadoresCabecilla
                      : getTrabajadores
                  }
                  getOptionLabel={(option) => option.nombre}
                  getOptionValue={(option) => option.id}
                  isSearchable={true}
                  isClearable={true}
                  labelKey="nombre"
                  valueKey="id"
                  required={true}
                  disabled={true}
                />
              </Grid>

              {/* Row 3 */}
              <Grid item xs={12} sm={12} md={6}>
                <React.Fragment>
                  {/* <Select
                      required={true}
                      name="tipo_bordado"
                      label="Tipo de bordado"
                      fullWidth
                      variant="standard"
                      value={data.tipo_bordado}
                    >
                      <MenuItem value={"NEGRO"}>NEGRO</MenuItem>
                </Select>*/}
                  <TextField
                    name="tipo_bordado"
                    label="Tipo de bordado"
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </React.Fragment>

                {/*data && data.tipo_bordado == "COMPLETO" && (
                  <React.Fragment>
                    <TextField
                      name="tipo_bordado"
                      label="Tipo de bordado"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    </React.Fragment>
                )*/}
              </Grid>

              {/* Row 4 */}
              <Grid item xs={12} sm={12} md={6}>
                <span className="font-weight-bold">Recibido: </span>
                <div>Angel</div>
              </Grid>

              {/* Row 5 */}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="pago"
                  label="Pago"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  variant="standard"
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} textAlign="center">
                <Button variant="contained" color="primary" type="submit">
                  Registrar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default FormRecibido;
