/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "./TableAsignacion";

const ListAsignacion = ({ value, search, catalogo }) => {
  return (
    <React.Fragment>
      {/*<div className="d-flex justify-content-end mb-2">
        <Link
          to="/asignacion_individual/create"
          className="btn btn-secondary  mr-3"
        >
          Asignacion individual
        </Link>
        <Link to="/asignacion_cabecilla/create" className="btn btn-secondary">
          Asignacion cabecilla
        </Link>
  </div>*/}
      <Table value={value} search={search} catalogo={catalogo} />
    </React.Fragment>
  );
};

export default ListAsignacion;
