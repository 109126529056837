import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

//ICONS
import DownloadIcon from "@mui/icons-material/Download";
import WorkIcon from "@mui/icons-material/Work";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaidIcon from "@mui/icons-material/Paid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import StarIcon from "@mui/icons-material/Star";

import { saveAs } from "file-saver";

//
import { manageErrors } from '../../../hooks/manageErrors'
import ReportesService from "./reportes.service";
import Filters from "./Filters";

//Others
import LoaderContext from "../../../context/ContextLoader";

const dayjs = require("dayjs");

export default function App() {

  const { loader, setLoader } = useContext(LoaderContext);
  const [valueDate, setValue] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );

  const onSubmit = (informe, tipo) => {
    setLoader(true);
    ReportesService.getDescargar(informe, dayjs(valueDate).year(), tipo) //params
      .then((response) => {
        //RESPONSE
          const dirtyFileName = response.headers['content-disposition'];
          const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
          var  fileName = dirtyFileName.match(regex)[3];
        
          var blob = new Blob([response.data], { type: 'arraybuffer' });
          saveAs(blob, fileName);
      })
      .catch((e) => {
        manageErrors(e);
      }).finally(()=>{
        setLoader(false);
      });
  };

  return (
    <>
      <div>
        <h5 className="ml-3 mt-3"><strong>Lista de Descargas</strong></h5>
      </div>

      <div mt={3}>
        <Filters value={valueDate} setValue={setValue} />
      </div>
      <Grid
        mt={4}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: 1200,
            bgcolor: "background.paper",
          }}
        >
          <Divider variant="inset" component="li" />
          {/* TRABAJADORES */}
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Trabajadores"  />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("trabajadores");
              }}
              startIcon={<DownloadIcon />}
            >
              Descargar
            </Button>
          </ListItem>
          {/* PRODUCTOS */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <InventoryIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Productos" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("productos");
              }}
              startIcon={<DownloadIcon />}
            >
              Descargar
            </Button>
          </ListItem>
          {/* ASIGNACION */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MenuBookIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Asignacion" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("asignacion", "CABECILLAS");
              }}
              startIcon={<DownloadIcon />}
            >
              D.Cabecillas
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("asignacion", "INDIVIDUALES");
              }}
              startIcon={<DownloadIcon />}
            >
              D.Individuales
            </Button>
          </ListItem>

          {/* ASIGNATURAS */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MenuBookIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Asignaturas" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("asignatura");
              }}
              startIcon={<DownloadIcon />}
            >
             Asignaturas
            </Button>
          </ListItem>

          {/* RECIBOS */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MenuBookIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Recibos" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("recibos");
              }}
              startIcon={<DownloadIcon />}
            >
             Descargar
            </Button>
          </ListItem>
          {/* CUENTAS */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <PaidIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Cuentas" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("cuentas");
              }}
              startIcon={<DownloadIcon />}
            >
              Descargar
            </Button>
          </ListItem>
          {/* TRABAJADORES */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FolderSharedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Usuarios" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("usuarios");
              }}
              startIcon={<DownloadIcon />}
            >
              Descargar
            </Button>
          </ListItem>
          {/* VENCIDOS */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ReportProblemIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Vencidos" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("vencidos", "CABECILLAS");
              }}
              startIcon={<DownloadIcon />}
            >
              D.CABECILLAS
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("vencidos", "INDIVIDUALES");
              }}
              startIcon={<DownloadIcon />}
            >
              D.INDIVIDUALES
            </Button>
          </ListItem>
          {/* REALIZADOS */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <BookmarkAddedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Realizados" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("realizados", "CABECILLAS");
              }}
              startIcon={<DownloadIcon />}
            >
              D.CABECILLAS
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("realizados", "INDIVIDUALES");
              }}
              startIcon={<DownloadIcon />}
            >
              D.INDIVIDUALES
            </Button>
          </ListItem>
          {/* PUNTAJES */}
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <StarIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Puntajes" secondary="" />
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("puntuacion", "CABECILLAS");
              }}
              startIcon={<DownloadIcon />}
            >
              D.CABECILLAS
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onSubmit("puntuacion", "INDIVIDUALES");
              }}
              startIcon={<DownloadIcon />}
            >
              D.INDIVIDUALES
            </Button>
          </ListItem>
        </List>
      </Grid>
    </>
  );
}
