/* eslint-disable */
import React, { Component } from "react";
//import TutorialDataService from "../services/tutorial.service";

import AsignacionService from "../asignacion.service";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";

import { manageErrors } from '../../../../hooks/manageErrors'

import TablaAsignacion from "./TablaAsignacion";

export default class TableAsignacion extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveData = this.retrieveData.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",

      page: 1,
      count: 0,
      pageSize: 10,
      catalogo: this.props.catalogo,
      search: this.props.search,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
    this.retrieveData();
  }

  componentDidUpdate() {
    if (this.props.catalogo != this.state.catalogo) {
      this.setState({ catalogo: this.props.catalogo });
      this.retrieveData();
    }
    if (this.props.search != this.state.search) {
      this.setState({ search: this.props.search });
      this.retrieveData();
    }
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  getRequestParams(searchTitle, page, pageSize) {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveData() {
    const { searchTitle, page, pageSize } = this.state;
    const params = this.getRequestParams(searchTitle, page, pageSize);

    let pag = "";

    if (this.props.value == 1) pag = "CABECILLAS";
    if (this.props.value == 2) pag = "INDIVIDUALES";
    if (this.props.value == 3) pag = "CABECILLAS_COMPLETADOS";
    if (this.props.value == 4) pag = "INDIVIDUALES_COMPLETADOS";

    let catalogo = this.props.catalogo;
    let search = this.props.search;

    AsignacionService.getContent(page, pag, catalogo, search) //params
      .then((response) => {
        const { data } = response.data;
        const { last_page } = response.data.meta;

        this.setState({
          tutorials: data,
          count: last_page,
        });
      })
      .catch((e) => {
        manageErrors(e);
      });
  }

  refreshList() {
    this.retrieveData();
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveData();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveData();
      }
    );
  }

  render() {
    const {
      searchTitle,
      tutorials,
      currentTutorial,
      currentIndex,
      page,
      count,
      pageSize,
    } = this.state;


    return (
      <React.Fragment>
        <div className="col-md-12">
          <h4>{this.props.title}</h4>
          <div className="expanded-container">
            <TablaAsignacion
              data={tutorials}
              reset={this.retrieveData}
              value={this.props.value}
              catalogo={this.props.value == 1 ? "CABECILLAS" : "INDIVIDUALES"}
            />
          </div>
          <div className="mt-3 d-flex justify-content-end">
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
