/* eslint-disable */
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import WorkIcon from "@mui/icons-material/Work";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaidIcon from "@mui/icons-material/Paid";
import Button from "@mui/material/Button";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import StarIcon from "@mui/icons-material/Star";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AuthService from "../../../services/auth.service";
import BootstrapTable from "react-bootstrap-table-next";
import RecentActorsIcon from '@mui/icons-material/RecentActors';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const text = {
  fontWeight: "bold",
};

const MenuList = ({ open, logOut }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();
  const currentRole = AuthService.getCurrentRole();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <React.Fragment>
      <Typography
        variant="h6"
        noWrap
        component="div"
        mt={3}
        mb={3}
        align="center"
      >
        Reino Maya
      </Typography>

      {currentUser && currentRole && (
        <React.Fragment>
          <Box sx={{ width: "100%" }} mb={2}>
            <Stack>
              <Button
                style={{ background: "#AAD8D3", color: "#393E46" }}
                onClick={() => {
                  history.push("/profile");
                }}
              >
                <AccountCircleIcon className="mr-2 mb" />
                <Typography sx={{ fontWeight: "bold" }}>
                  {currentUser["user"]["nombre"]}
                </Typography>
              </Button>

              <Item style={{ background: "#ECB365" }}>
                {currentUser && currentRole ? (
                  <Typography sx={{ fontWeight: "bold" }}>
                    {currentRole}
                  </Typography>
                ) : (
                  " -------- "
                )}
              </Item>
            </Stack>
          </Box>

          <ListItem
            key={"Trabajadores"}
            disablePadding
            sx={{ display: "block" }}
          >
            {/* Trabajadores */}
            <ListItemButton
              className={
                location.pathname === "/trabajadores" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/trabajadores");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <WorkIcon style={{ color: "#EEEEEE" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Trabajadores"}
                sx={{ opacity: 1 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem key={"Productos"} disablePadding sx={{ display: "block" }}>
            {/* Productos */}
            <ListItemButton
              className={
                location.pathname === "/productos" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/productos");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <InventoryIcon style={{ color: "#EEEEEE" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Productos"}
                sx={{ opacity:  1 }}
              />
            </ListItemButton>
          </ListItem>

          {/* Asignacion */}
          <ListItem key={"Asignacion"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className={
                location.pathname === "/asignacion" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/asignacion");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <MenuBookIcon style={{ color: "#FFFFFF" }} />
              </ListItemIcon>
              <ListItemText
                style={text}
                primary={"Asignacion"}
                sx={{ opacity: 1 }}
              />
            </ListItemButton>
          </ListItem>

          {/* Cuentas */}
          <ListItem key={"Cuentas"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className={location.pathname === "/cuentas" ? "activeMenu" : null}
              onClick={() => {
                history.push("/cuentas");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <PaidIcon style={{ color: "#FFFFFF" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Cuentas"}
                sx={{ opacity:  1 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem key={"Usuarios"} disablePadding sx={{ display: "block" }}>
            {/* Trabajadores */}
            <ListItemButton
              className={
                location.pathname === "/usuarios" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/usuarios");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <FolderSharedIcon style={{ color: "#EEEEEE" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Usuarios"}
                sx={{ opacity: 1 }}
              />
            </ListItemButton>
          </ListItem>

          <Divider />
          {/* Vencidos */}
          <ListItem key={"Vencidos"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className={
                location.pathname === "/vencidos" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/vencidos");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <ReportProblemIcon style={{ color: "#FFFFFF" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Vencidos"}
                sx={{ opacity:  1 }}
              />
            </ListItemButton>
          </ListItem>

          {/* Realizados */}
          <ListItem key={"Realizados"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className={
                location.pathname === "/realizados" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/realizados");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <BookmarkAddedIcon style={{ color: "#FFFFFF" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Realizados"}
                sx={{ opacity:  1 }}
              />
            </ListItemButton>
          </ListItem>

          {/* Puntajes */}
          <ListItem key={"Puntajes"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className={
                location.pathname === "/puntuacion" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/puntuacion");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <StarIcon style={{ color: "#FFFFFF" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Puntajes"}
                sx={{ opacity:  1 }}
              />
            </ListItemButton>
          </ListItem>

          {/* Puntajes */}
          <ListItem key={"cabecillasTotal"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className={
                location.pathname === "/cabecillasTotal" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/cabecillasTotal");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <RecentActorsIcon style={{ color: "#FFFFFF" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Total Prod.Cab"}
                sx={{ opacity:  1 }}
              />
            </ListItemButton>
          </ListItem>

          {/* Reportes */}
          <ListItem key={"Reportes"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className={
                location.pathname === "/reportes" ? "activeMenu" : null
              }
              onClick={() => {
                history.push("/reportes");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  pr: 2, 
                }}
              >
                <AssignmentReturnedIcon style={{ color: "#FFFFFF" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Descargas"}
                sx={{ opacity: 1 }}
              />
            </ListItemButton>
          </ListItem>
          <Box sx={{ width: "100%" }}>
            <Stack>
              <Button
                style={{ background: "#393E46", color: "#FFFFFF" }}
                onClick={() => {
                  logOut();
                }}
              >
                <ExitToAppIcon className="mr-2 mb" />
                <Typography sx={{ fontWeight: "bold" }}>SALIR</Typography>
              </Button>
            </Stack>
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MenuList;

{
  /*["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
        <ListItem key={text} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => {
              history.push("/home");
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: isMobile ? 0 : 1 }} />
          </ListItemButton>
        </ListItem>
            ))}*/
}
