import React, { useState } from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";

import UpdateFormAsignacion from "./Asignacion/UpdateFormAsignacion";
import FormRecibido from "./Recibido/Recibido";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zindex: 100,
};

const ActionsAsignacion = ({ data, reset, producto, catalogo="" }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openRecibido, setOpenRecibido] = useState(false);
  const handleOpenRecibido = () => setOpenRecibido(true);
  const handleCloseRecibido = () => setOpenRecibido(false);

  return (
    <div>
      <EditIcon onClick={handleOpen} className="mr-2" />
      <ReceiptIcon onClick={handleOpenRecibido} className="mr-2" />

      {/* Asignacion */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Asignacion
          </Typography>
          <UpdateFormAsignacion
            catalogo={catalogo}
            data={data}
            handleClose={handleClose}
            reset={reset}
            producto={producto}
          />
        </Box>
      </Modal>
      {/* Recibido */}
      <Modal
        open={openRecibido}
        onClose={handleCloseRecibido}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Recibir
          </Typography>

          <FormRecibido
            data={data}
            handleClose={handleCloseRecibido}
            reset={reset}
            producto={producto}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ActionsAsignacion;
