import React, { useState, useContext } from "react";

import Grid from "@mui/material/Grid";

import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isObjEmpty } from "../../../../Utils/IsEmptyObject/IsEmptyObject";

import InputLabel from "@mui/material/InputLabel";
import { Form, Field } from "react-final-form";

import {
  TextMaskCustom,
  NumberFormatCustom,
  AsyncSelectField,
} from "../../../../../utils/renderField/renderField";

//Services
import TrabajadorService from "../../../Trabajador/trabajador.service";
import AsignacionService from "../../asignacion.service";

//Others
import { manageErrors } from '../../../../../hooks/manageErrors';

const dayjs = require("dayjs");

const theme = createTheme({
  palette: {
    neutral: {
      main: "#6c757d",
      contrastText: "#fff",
    },
  },
});

const CreateAsignacionInvidiualForm = ({ onSubmit }) => {
  const [filter, setFilter] = useState("codigo");
  const [nuevo, setNuevo] = useState(false);
  //Search
  const getProductos = (search) => {
    return AsignacionService.getSearchProductsContent(1, search, filter)
      .then((response) => {
        const { data } = response.data;
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  //Search
  const getTrabajadores = (search) => {
    return TrabajadorService.getTrabajadorContentIndividual(1, search, true)
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  const validate = (dataValues) => {
    const { initialValues } = {};
    const requerido_msg = "Este campo es requerido";

    let erros = { ...initialValues };

    let values = { ...dataValues };

    if (!values.tipo_bordado) {
      erros.tipo_bordado = requerido_msg;
    }

    if (nuevo) {
      values.trabajador_nuevo_individual = true;

      values.id_cabecilla = null;
      values.tipo = "INDIVIDUAL";

      if (!values.referencia) {
        erros.referencia = requerido_msg;
      }

      if (!values.telefono) {
        erros.telefono = requerido_msg;
      }

      if (!values.nombre) {
        erros.nombre = requerido_msg;
      }

    } else {
      delete values.trabajador_nuevo_individual;

      if (!values.id_trabajador) {
        erros.id_trabajador = requerido_msg;
      }

    }

    //Other

    if (values.tipo_bordado === "NEGRO") {
      values.fecha = dayjs(values.fecha).add(2, "month").format("YYYY-MM-DD");
    } else if (values.tipo_bordado === "COMPLETO") {
      values.fecha = dayjs(values.fecha).add(6, "month").format("YYYY-MM-DD");
    }
    if (!values.precio) values.precio = 0;
    if (!values.aumento) values.aumento = 0;

    let checkErrors = isObjEmpty(erros);

    if (checkErrors) {
      onSubmit(values);
    } else {
      return erros;
    }
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={validate}
        initialValues={{ tipo: filter, fecha: dayjs(new Date(), "DD-MM-YYYY") }}
        validate={() => {}}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <Grid container spacing={2}>
              {/* Row 1 */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                align={"right"}
                className="font-weight-bold"
              >
                Fecha: {dayjs(values.fecha).format("YYYY-MM-DD")}
              </Grid>

              {/* Row 2 */}
              <Grid item xs={12} sm={12} md={3}>
                <InputLabel htmlFor="tipo">Tipo *</InputLabel>
                <Select
                  required={true}
                  name="tipo"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <MenuItem value={"codigo"}>Codigo</MenuItem>
                  <MenuItem value={"nombre"}>Nombre</MenuItem>
                </Select>
              </Grid>

              {/* Row 3 */}
              <Grid item xs={12} sm={12} md={9}>
                <InputLabel htmlFor="cabecilla">Producto *</InputLabel>
                <Field
                  name="producto"
                  label="Producto"
                  component={AsyncSelectField}
                  loadOptions={getProductos}
                  getOptionLabel={(option) =>
                    `${option["codigo"] + " - " + option["nombre"]}`
                  } //option.nombre
                  getOptionValue={(option) => option.id}
                  isSearchable={true}
                  isClearable={true}
                  labelKey="codigo"
                  valueKey="id"
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <ThemeProvider theme={theme}>
                  {!nuevo && (
                    <Button
                      color="neutral"
                      variant="contained"
                      onClick={() => {
                        setNuevo(true);
                      }}
                    >
                      Nuevo Trabajador
                    </Button>
                  )}

                  {nuevo && (
                    <Button
                      color="neutral"
                      variant="contained"
                      onClick={() => {
                        setNuevo(false);
                      }}
                    >
                      Buscar Trabajador
                    </Button>
                  )}
                </ThemeProvider>
              </Grid>

              {nuevo && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="nombre"
                    label="Nombre"
                    fullWidth
                    variant="standard"
                    autoComplete="off"
                    required={true}
                  />
                </Grid>
              )}

              {nuevo && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="telefono"
                    label="Telefono"
                    fullWidth
                    variant="standard"
                    autoComplete="off"
                    required={true}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />
                </Grid>
              )}

              {/*JSON.stringify(data.cabecilla)*/}

              {nuevo && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    required={true}
                    name="referencia"
                    label="Referencia de direccion"
                    fullWidth
                    multiline
                    rows={2}
                    maxRows={5}
                    variant="standard"
                    autoComplete="off"
                  />
                </Grid>
              )}

              {/* Row 4 */}
              {!nuevo && (
                <Grid item xs={12} sm={12} md={6}>
                  <InputLabel
                    //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                    htmlFor="cabecilla"
                  >
                    Trabajador Individual *
                  </InputLabel>
                  <Field
                    name="id_trabajador"
                    label="Trabajador"
                    component={AsyncSelectField}
                    loadOptions={getTrabajadores}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    isSearchable={true}
                    isClearable={true}
                    labelKey="nombre"
                    valueKey="id"
                    required={true}
                  />
                </Grid>
              )}

              {/* Row 5 */}
              <Grid item xs={12} sm={12} md={6}>
                <Select
                  required={true}
                  name="tipo_bordado"
                  label="Tipo de bordado"
                  fullWidth
                  variant="standard"
                >
                  <MenuItem value={"NEGRO"}>Negro</MenuItem>

                  <MenuItem value={"COMPLETO"}>Completo</MenuItem>
                </Select>
              </Grid>

              {/* Row 6 */}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="precio"
                  label="Precio"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    readOnly: true,
                  }}
                  value={
                    values.producto
                      ? values.tipo_bordado == "COMPLETO"
                        ? Number(
                            values.producto.precio_negro +
                              values.producto.precio_color
                          )
                        : values.tipo_bordado == "NEGRO"
                        ? values.producto.precio_negro
                        : 0
                      : 0
                  }
                  fullWidth
                  variant="standard"
                  autoComplete="off"
                  required={true}
                />
              </Grid>

              {/* Row 7 */}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="aumento"
                  label="Aumento"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  variant="standard"
                  autoComplete="off"
                  required={true}
                />
              </Grid>

              {/* Row 9 */}
              <Grid item xs={12} sm={12} textAlign="center">
                <Link
                  to="/asignacion/"
                  className="btn btn-secondary mr-3 mt-md-0"
                >
                  Volver
                </Link>
                <Button variant="contained" color="primary" type="submit">
                  Registrar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default CreateAsignacionInvidiualForm;
