import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import Button from "@mui/material/Button";
//Icons
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
//import IconButton from "@mui/material/IconButton";
//import BorderColorIcon from "@mui/icons-material/BorderColor";

import ModalAsignacion from "./ModalAsignacion";
import ActionsAsignacion from "./Actions";

const dayjs = require("dayjs");

const TablaAsignacion = ({ data = [], reset, value, anio = 2022 }) => {
  const columns = [
    {
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },
      dataField: "nom_trabajador",
      text: "Trabajador",
    },
    {
      headerStyle: (colum, colIndex) => {
        return {  width: "180px", backgroundColor: '#e0e0e0' };
      },
      dataField: "total",
      text: "Puntos",
    },
    {
      headerStyle: (colum, colIndex) => {
        return {  width: "180px", backgroundColor: '#e0e0e0' };
      },
      dataField: "c_calidad",
      text: "Control de calidad",
      formatter: (cell, row) => {
        const { totalPuntuacion } = row;

        return (
          <React.Fragment>
            {totalPuntuacion ? totalPuntuacion + "/5" : "----------"}
          </React.Fragment>
        );
      },
    },
  ];

  const columns_cabecillas = [
    {
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },
      dataField: "nom_trabajador",
      text: "Trabajador",
    },
    {
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },
      dataField: "nom_cabecilla",
      text: "Cabecilla",
    },
    {
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },
      dataField: "total",
      text: "Puntos",
    },
    {
      headerStyle: (colum, colIndex) => {
        return { backgroundColor: '#e0e0e0' };
      },  
      dataField: "c_calidad",
      text: "Control de calidad",
      formatter: (cell, row) => {
        const { totalPuntuacion } = row;

        return (
          <React.Fragment>
            {totalPuntuacion ? totalPuntuacion + "/5" : "----------"}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <BootstrapTable
          keyField="nom_trabajador"
          data={data}
          columns={value === 1 ? columns_cabecillas : columns}
        />
      </Box>
    </React.Fragment>
  );
};

export default TablaAsignacion;
