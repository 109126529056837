import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";
import { useHistory, useParams } from "react-router-dom";

import { DatePicker } from "mui-rff";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import InputLabel from "@mui/material/InputLabel";
import { Form, Field } from "react-final-form";

import {
  TextMaskCustom,
  NumberFormatCustom,
  AsyncSelectField,
} from "../../../../../utils/renderField/renderField";

//Services
import TrabajadorService from "../../../Trabajador/trabajador.service";
import PuntuacionService from "../../puntuacion.service";

//Others
import { manageErrors } from '../../../../../hooks/manageErrors'
import Swal from "sweetalert2";

const dayjs = require("dayjs");
const UpdateFormAsignacion = ({ data = [], handleClose, reset }) => {
  let history = useHistory();

  const onSubmit = (dataValues) => {
    let values = { ...dataValues };
    values.trabajador_id = values.id_trabajador.id;
    //values.salida_id = data.id;

    delete values.id_trabajador;
    delete values.trabajador;
    delete values.created_at;
    delete values.updated_at;

    PuntuacionService.updateAsignacion(values, values.id)
      .then((response) => {
        if (response && response.status == 200) {
          Swal.fire("Guardado!", "", "success");
          //history.push("/asignacion");
          handleClose();
          reset();
        }
      })
      .catch((e) => {
        manageErrors(e);
        //Swal.fire("Guardado!", "", "success");
      });
  };

  //Search
  const getTrabajadoresCabecilla = (search) => {
    return PuntuacionService.getTrabajadorContentAsignacionCabecilla(
      1,
      search,
      true,
      data.trabajador.id_cabecilla
    )
      .then((response) => {
        const { data } = response.data;
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  //Search
  const getTrabajadores = (search) => {
    return TrabajadorService.getTrabajadorContentIndividual(1, search, true)
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  const validate = (dataValues) => {
    let values = { ...dataValues };
    values.fecha = dayjs(values.fecha).format("YYYY-MM-DD");
    if (values.tipo_bordado == "NEGRO") {
      values.fecha = dayjs(values.created_at)
        .add(2, "month")
        .format("YYYY-MM-DD");
    } else if (values.tipo_bordado == "COMPLETO") {
      values.fecha = dayjs(values.created_at)
        .add(6, "month")
        .format("YYYY-MM-DD");
    }
    onSubmit(values);
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={validate}
        initialValues={{ ...data, id_trabajador: data.trabajador }}
        validate={() => {}}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className="mt-4" noValidate>
            <Grid container spacing={2}>
              {/* Row 1 
              <Grid item xs={12} sm={12} md={6} className="font-weight-bold">
                <div align={"left"}>Producto: {data.producto.codigo}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="font-weight-bold">
                <div align={"right"}>
                  Fecha: {dayjs(values.fecha).format("YYYY-MM-DD")}
                </div>
              </Grid>*/}

              {/* Row 2 */}
              <Grid item xs={12} sm={12} md={6}>
                <InputLabel
                  //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                  htmlFor="cabecilla"
                >
                  Trabajador Individual *
                </InputLabel>
                <Field
                  name="id_trabajador"
                  label="Trabajador"
                  component={AsyncSelectField}
                  loadOptions={
                    data && data.trabajador && data.trabajador.id_cabecilla
                      ? getTrabajadoresCabecilla
                      : getTrabajadores
                  }
                  getOptionLabel={(option) => option.nombre}
                  getOptionValue={(option) => option.id}
                  isSearchable={true}
                  isClearable={true}
                  labelKey="nombre"
                  valueKey="id"
                  required={true}
                />
              </Grid>

              {/* Row 3  */}
              <Grid item xs={12} sm={12} md={6}>
                {/* Row 3 
                {data && data.tipo_bordado == "NEGRO" && (
                  <React.Fragment>
                    <Select
                      required={true}
                      name="tipo_bordado"
                      label="Tipo de bordado"
                      fullWidth
                      variant="standard"
                    >
                      <MenuItem value={"NEGRO"}>Negro</MenuItem>
                    </Select>
                  </React.Fragment>
                )}*/}

                {data &&
                (data.tipo_bordado == "COMPLETO" ||
                  data.tipo_bordado == "NEGRO") ? (
                  <React.Fragment>
                    <Select
                      required={true}
                      name="tipo_bordado"
                      label="Tipo de bordado"
                      fullWidth
                      variant="standard"
                    >
                      <MenuItem value={"NEGRO"}>NEGRO</MenuItem>
                      <MenuItem value={"COMPLETO"}>COMPLETO</MenuItem>
                    </Select>
                  </React.Fragment>
                ) : (
                  <TextField
                    name="tipo_bordado"
                    label="Tipo de bordado"
                    value={"COLOR"}
                    fullWidth
                    variant="standard"
                    required={true}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              </Grid>

              {/* Row 4 */}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="precio"
                  label="Precio"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  variant="standard"
                  required={true}
                />
              </Grid>

              {/* Row 5 */}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name="aumento"
                  label="Aumento"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  fullWidth
                  variant="standard"
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} textAlign="center">
                <Button variant="contained" color="primary" type="submit">
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default UpdateFormAsignacion;
