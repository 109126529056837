import React, { useState } from "react";

import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import UpdateFormAsignacion from "./Asignacion/UpdateFormAsignacion";

const dayjs = require("dayjs");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zindex: 100,
};

const DetalleRecibo = ({ data, reset, producto }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <VisibilityIcon onClick={handleOpen} className="mr-2" />

      {/* Asignacion */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="fw-bold"
            variant="h6"
            component="h2"
          >
            <strong>Detalle de Recibo</strong>
          </Typography>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <span className="fw-bolder"><strong>Fecha de Entrega:</strong></span>{" "}
              {dayjs(data.fecha).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={12} md={6}>
              {data.completado === 1 ? (
                <span>
                  <strong>Fecha de Recibido:</strong> {" "}
                  {dayjs(data.updated_at).format("DD/MM/YYYY")}
                </span>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <strong>Trabajador:</strong> {data.trabajador.nombre}
            </Grid>
            <Grid item xs={12} md={6}>
              <strong>Tel:</strong> {data.trabajador.telefono}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <strong>Precio:</strong> Q.{data.precio}
            </Grid>
            <Grid item xs={12} md={6}>
              <strong>Aumento:</strong> Q.{data.aumento}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <strong>Puntuacion:</strong> {data.puntuacion}/5
            </Grid>
          </Grid>

        </Box>
      </Modal>
    </div>
  );
};

export default DetalleRecibo;
