import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import ReactDOM, { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Form, Field } from "react-final-form";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";

import InputLabel from "@mui/material/InputLabel";
import {
  AsyncSelectField,
} from "../../../../../utils/renderField/renderField";

//Services
import TrabajadorService from "../../../Trabajador/trabajador.service";

import { manageErrors } from '../../../../../hooks/manageErrors'

const AsignacionTrabajadorForm = ({ onSubmit, id }) => {
  //Search
  const getTrabajadores = (search) => {
    return TrabajadorService.getTrabajadorContent(1, search, true)
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        render={({ handleSubmit, reset, submitting, pristine, form }) => (
          <form
            onSubmit={async (event) => {
              await handleSubmit(event);
              //getTrabajadores();
              //form.reset();
            }}
            noValidate
          >
            {/* Row 1 */}
            <Grid item xs={12}>
              <InputLabel htmlFor="cabecilla">Cabecilla *</InputLabel>
              <Field
                name="id_cabecilla"
                label="Cabecilla"
                component={AsyncSelectField}
                loadOptions={getTrabajadores}
                getOptionLabel={(option) => option.nombre}
                getOptionValue={(option) => option.id}
                isSearchable={true}
                isClearable={true}
                labelKey="nombre"
                valueKey="id"
                //disabled={values.tipo == "TRABAJADOR" ? false : true}
                required={true}
              />
            </Grid>
            <br />
            <Grid item xs={12} sm={12} textAlign="center">
              <Link
                to="/asignacion/"
                className="btn btn-secondary mr-3 mt-md-0"
              >
                Volver
              </Link>
              <Button variant="contained" color="primary" type="submit">
                Registrar
              </Button>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default AsignacionTrabajadorForm;
