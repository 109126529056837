import axios from "axios";
import authHeader from "../../../services/auth-header";
import CONST from "../../../consts/const";

const API_URL = CONST.API_URL; //"http://localhost:8080/api/test/";

class RealizadosService {
  //List
  getContent(page, pag, catalogo, search = "", anio) {
    return axios.get(
      API_URL +
        "totalproductosCabecillas?anio=" +
        anio,
      {
        headers: authHeader(),
      }
    );
  }

}

export default new RealizadosService();
