/* eslint-disable */
import React, { Component } from "react";
import Table from '../../../Table/Table'

import ProductoService from "../producto.service";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

//Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

//Services
import Swal from "sweetalert2";

import AuthService from "../../../../services/auth.service";
import { manageErrors } from '../../../../hooks/manageErrors'

export default class TableProducto extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",

      page: 1,
      count: 0,
      pageSize: 10,
      status: this.props.status,
      search: this.props.search,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
    this.retrieveTutorials();
  }

  componentDidUpdate() {
    if (this.props.status != this.state.status) {
      this.setState({ status: this.props.status });
      this.retrieveTutorials();
    }
    if (this.props.search != this.state.search) {
      this.setState({ search: this.props.search });
      this.retrieveTutorials();
    }
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  getRequestParams(searchTitle, page, pageSize) {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveTutorials() {
    const { searchTitle, page, pageSize } = this.state;
    const params = this.getRequestParams(searchTitle, page, pageSize);

    let pag = "";

    if (this.props.value) pag = this.props.value;

    let status = this.props.status;
    let search = this.props.search;

    ProductoService.get(page, pag, status, search) //params
      .then((response) => {
        const { data } = response.data;
        const { last_page } = response.data.meta;

        this.setState({
          tutorials: data,
          count: last_page,
        });
      })
      .catch((e) => {
        manageErrors(e);
      });
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  render() {
    const {
      searchTitle,
      tutorials,
      currentTutorial,
      currentIndex,
      page,
      count,
      pageSize,
    } = this.state;

    const currentRole = AuthService.getCurrentRole();

    const columns_admin = [
      {
        headerClassName: 'header-table', 
        field: "codigo",
        headerName: "Codigo",
        width: 150,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <span>
              {row.letra ? row.letra + `-` : ""}
              {row.id}
            </span>
          );
        },
      },
      { headerClassName: 'header-table', field: "nombre", headerName: "Nombre", width: 150,  flex: 1 },
      {
        headerClassName: 'header-table', 
        field: "estado",
        headerName: "Estado",
        width: 150,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <span>
              {row.estado == "INACTIVO" && row.asignado == 0
                ? "BODEGA"
                : row.estado}
            </span>
          );
        },
      },
      { headerClassName: 'header-table', field: "catalogo", headerName: "Catalogo", width: 150,  flex: 1 },
      { headerClassName: 'header-table', field: "descripcion", headerName: "Descripcion", width: 250,  flex: 1 },
      {
        headerClassName: 'header-table', 
        field: "acciones",
        headerName: "Acciones",
        width: 150,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <React.Fragment>
              <Link
                className="bas fa-plus-circle ml-3 icons-actions"
                fontSize="small"
                to={`/producto/edit/${row.id}`}
              >
                <EditIcon />
              </Link>
            </React.Fragment>
          );
        },
      },
    ];

    const columns = [
      {
        headerClassName: 'header-table', 
        field: "codigo",
        headerName: "Codigo",
        width: 150,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <span>
              {row.letra ? row.letra + `-` : ""}
              {row.id}
            </span>
          );
        },
      },
      { headerClassName: 'header-table', field: "nombre", headerName: "Nombre", width: 150, flex: 1 },
      {
        headerClassName: 'header-table', 
        field: "estado",
        headerName: "Estado",
        width: 150,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
            <span>
              {row.estado == "INACTIVO" && row.asignado == 0
                ? "BODEGA"
                : row.estado}
            </span>
          );
        },
      },
      { headerClassName: 'header-table', field: "catalogo", headerName: "Catalogo", width: 150,  flex: 1 },
      { headerClassName: 'header-table', field: "descripcion", headerName: "Descripcion", width: 250,  flex: 1 },
    ];

    return (
      <React.Fragment>
        {currentRole && (
          <div className="col-md-12">
            <h4>{this.props.title}</h4>
            <Table
              data={tutorials}
              columns={currentRole === "ADMIN" ? columns_admin : columns}
            />

            <div className="mt-3 d-flex justify-content-end">
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
