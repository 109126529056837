import axios from "axios";
import authHeader from "../../../services/auth-header";
import CONST from "../../../consts/const";

const API_URL = CONST.API_URL; //"http://localhost:8080/api/test/";

class CuentasService {
  //List
  getContent(page, pag, catalogo, search = "") {
    return axios.get(
      API_URL + "cuenta?page=" + page + "&tipo=" + pag, //+
      //"&catalogo=" +
      //catalogo +
      //"&search=" +
      //search,
      {
        headers: authHeader(),
      }
    );
  }

  //List MOvimiento
  getMovimiento(page, id_cuenta = "") {
    // search = ""
    return axios.get(
      API_URL + "movimiento?page=" + page + "&id_cuenta=" + id_cuenta, //+
      //"&catalogo=" +
      //catalogo +
      //"&search=" +
      //search,
      {
        headers: authHeader(),
      }
    );
  }

  //Get Item
  getCabecilla(id) {
    return axios.get(API_URL + "trabajador/" + id, {
      headers: authHeader(),
      params: { cuenta: true },
    });
  }

  //Create Movimiento
  createMovimiento(data) {
    return axios.post(API_URL + "movimiento", data, {
      headers: authHeader(),
    });
  }

  //Create
  createContent(data) {
    return axios.post(API_URL + "salida", data, {
      headers: authHeader(),
    });
  }
}

export default new CuentasService();
