import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Form, Field } from "react-final-form";
import InputLabel from "@mui/material/InputLabel";

import { TextField } from "mui-rff";
import { Select } from "mui-rff";

import MenuItem from "@mui/material/MenuItem";
import { isObjEmpty } from "../../../Utils/IsEmptyObject/IsEmptyObject";
import {
  TextMaskCustom,
  AsyncSelectField,
} from "../../../../utils/renderField/renderField";

//Services
import { manageErrors } from '../../../../hooks/manageErrors'
import TrabajadorService from "../trabajador.service";

const CreateTrabajadorForm = ({ onSubmit, data, id }) => {
  //Search
  const getTrabajadores = (search) => {
    return TrabajadorService.getTrabajadorContent(1, search, true)
      .then((response) => {
        const { data } = response.data;
        const filteredArray = data.filter((item) => item.id !== Number(id));
        return filteredArray;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  // Others
  const { initialValues } = {};
  const requerido_msg = "Este campo es requerido";

  async function submit(values) {
    let erros = { ...initialValues };
    
    if (!values.nombre || values.nombre.trim() === '') {
      erros.nombre = requerido_msg;
    }
    if (!values.iglesia || values.iglesia.trim() === '') {
      erros.iglesia = requerido_msg;
    }
    if (!values.telefono || values.telefono.trim() === '') {
      erros.telefono = requerido_msg;
    }
    if (!values.tipo) {
      erros.tipo = requerido_msg;
    }

    if (values.tipo && values.tipo === "TRABAJADOR") {
      if (!values.id_cabecilla) {
        erros.id_cabecilla = requerido_msg;
      }
    } else if (values.tipo && values.tipo === "CABECILLA") {
      values.id_cabecilla = null;
    }

    let checkErrors = isObjEmpty(erros);

    if (checkErrors) {
      onSubmit(values);
    } else {
      return erros;
    }
  }

  return (
    <React.Fragment>
      <Form
        onSubmit={submit}
        initialValues={data}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              {/* Row 0 */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="nombre"
                  label="Nombre"
                  fullWidth
                  variant="standard"
                  required={true} 
                  autoComplete="off"
                />
              </Grid>

              {/* Row 1 */}
              <Grid item xs={12} sm={6}>
                <Select
                  required={true}
                  name="tipo"
                  label="Tipo"
                  fullWidth
                  variant="standard"
                >
                  <MenuItem value={"TRABAJADOR"}>Trabajador</MenuItem>
                  <MenuItem value={"CABECILLA"}>Cabecilla</MenuItem>
                  <MenuItem value={"INDIVIDUAL"}>Individual</MenuItem>
                </Select>
              </Grid>

              {/* Row 2 */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="iglesia"
                  label="Iglesia"
                  fullWidth
                  variant="standard"
                  required={true}
                  autoComplete="off"
                />
              </Grid>

              {/* Row 3 */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="telefono"
                  label="Telefono"
                  fullWidth
                  variant="standard"
                  autoComplete="off"
                  required={true}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
              </Grid>

              {/* Row 4 */}
              <Grid item xs={12} sm={12}>
                <TextField
                  required={true}
                  name="referencia"
                  label="Referencia de direccion"
                  fullWidth
                  multiline
                  rows={2}
                  maxRows={5}
                  variant="standard"
                  autoComplete="off"
                />
              </Grid>

              <hr />

              {/* Row 4 */}
              <Grid item xs={12} sm={12} md={6}>
                <InputLabel
                  htmlFor="cabecilla"
                >
                  Cabecilla *
                </InputLabel>
                <Field
                  name="id_cabecilla"
                  label="Cabecilla"
                  component={AsyncSelectField}
                  loadOptions={getTrabajadores}
                  getOptionLabel={(option) => option.nombre}
                  getOptionValue={(option) => option.id}
                  isSearchable={true}
                  isClearable={true}
                  labelKey="nombre"
                  valueKey="id"
                  disabled={values.tipo === "TRABAJADOR" ? false : true}
                  required={values.tipo === "TRABAJADOR" ? true : false}
                />
              </Grid>

              {/* Row 5 */}
              <Grid item xs={12} sm={12} textAlign="center">
                <Link
                  to="/trabajadores/"
                  className="btn btn-secondary mr-3 mt-md-0"
                >
                  Volver
                </Link>
                <Button variant="contained" color="primary" type="submit">
                  Registrar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default CreateTrabajadorForm;

/*
<pre className="mt2">{JSON.stringify(values)}</pre>
              
 {
 <Grid item xs={12} sm={12}>
 <TextField
   name="precio"
   label="Precio"
   InputProps={{
     inputComponent: NumberFormatCustom,
   }}
   variant="standard"
 />
</Grid>
<Grid item xs={12} sm={12}>
 <TextField
   name="xdfa"
   label="sfd"
   InputProps={{
     inputComponent: TextMaskCustom,
   }}
   variant="standard"
 />
</Grid>
 */
