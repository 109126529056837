/* eslint-disable */
import React, { Component, useRef } from "react";
//import TutorialDataService from "../services/tutorial.service";

import ReactToPrint from "react-to-print";
import RealizadosService from "../realizados.service";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

import { manageErrors } from '../../../../hooks/manageErrors'
import TablaAsignacion from "./TablaAsignacion";

export default class TableAsignacion extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveData = this.retrieveData.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",

      page: 1,
      count: 0,
      pageSize: 10,
      anio: this.props.anio,
      catalogo: this.props.catalogo,
      search: this.props.search,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
    this.retrieveData();
  }

  componentDidUpdate() {
    if (this.props.catalogo != this.state.catalogo) {
      this.setState({ catalogo: this.props.catalogo });
      this.retrieveData();
    }
    if (this.props.anio != this.state.anio) {
      this.setState({ anio: this.props.anio });
      this.retrieveData();
    }
    if (this.props.search != this.state.search) {
      this.setState({ search: this.props.search });
      this.retrieveData();
    }
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  getRequestParams(searchTitle, page, pageSize) {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveData() {
    const { searchTitle, page, pageSize } = this.state;
    const params = this.getRequestParams(searchTitle, page, pageSize);

    let pag = "";

    if (this.props.value == 1) pag = "CABECILLAS";
    if (this.props.value == 2) pag = "INDIVIDUALES";

    let catalogo = this.props.catalogo;
    let search = this.props.search;

    let anio = this.props.anio;

    RealizadosService.getContent(page, pag, catalogo, search, anio) //params
      .then((response) => {
        const { data } = response.data;
        const { last_page } = response.data.meta;

        this.setState({
          tutorials: data,
          count: last_page,
        });
      })
      .catch((e) => {
        manageErrors(e);
      });
  }

  refreshList() {
    this.retrieveData();
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveData();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveData();
      }
    );
  }

  render() {
    const {
      searchTitle,
      tutorials,
      currentTutorial,
      currentIndex,
      page,
      count,
      pageSize,
    } = this.state;

    const rows = [
      { id: 1, col1: "Hello", col2: "World" },
      { id: 2, col1: "DataGridPro", col2: "is Awesome" },
      { id: 3, col1: "MUI", col2: "is Amazing" },
    ];

    const columns = [
      { field: "id", headerName: "ID", width: 150 },
      { field: "nombre", headerName: "Nombre", width: 150 },
      { field: "apodo", headerName: "Apodo", width: 150 },
      { field: "iglesia", headerName: "Iglesia", width: 150 },
      { field: "referencia", headerName: "Referencia", width: 150 },
      { field: "telefono", headerName: "Telefono", width: 150 },
      { field: "tipo", headerName: "Tipo", width: 150 },
    ];

    const ComponentToPrint = React.forwardRef((props, ref) => {
      return (
        <div ref={ref}>
          <TablaAsignacion
            data={tutorials}
            reset={this.retrieveData}
            value={this.props.value}
            anio={this.props.anio}
          />
        </div>
      );
    });

    {
      /*<div ref={ref}>My cool content here!</div>;*/
    }
    return (
      <React.Fragment>
        <div className="col-md-12">
          <h4>{this.props.title}</h4>
          {/*<DataGrid
              hideFooter={true}
              hideFooterRowCount={true}
              hideFooterSelectedRowCount={true}
              hideFooterPagination={true}
              dataSet={"Commodity"}
              rows={tutorials}
              columns={columns}
    />*/}{" "}
          <div className="expanded-container">
            <div>
              <ReactToPrint
                documentTitle={
                  +this.props.value == 1
                    ? "InformeRealizadosCabecillas"
                    : "InformeRealizadosIndividuales"
                }
                pageStyle={`
                  @page  
                  { 
                      size: auto;   /* auto is the initial value */ 

                      /* this affects the margin in the printer settings */ 
                      margin: 25mm 25mm 25mm 25mm;  
                  } 

                  body  
                  { 
                      /* this affects the margin on the content before sending to printer */ 
                      margin: 0px;  
                  }
                  

                `}
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return <a href="#">Imprimir</a>;
                }}
                content={() => this.componentRef}
              />
              <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            </div>
            {/*<TablaAsignacion
              data={tutorials}
              reset={this.retrieveData}
              value={this.props.value}
              />*/}
          </div>
          <div className="mt-3 d-flex justify-content-end">
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={this.handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
