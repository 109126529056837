/* eslint-disable */
import React, { Component } from "react";
import Table from '../../../Table/Table'

import { Link } from "react-router-dom";
import TrabajadorService from "../trabajador.service";
import Pagination from "@mui/material/Pagination";

//Icons
import EditIcon from "@mui/icons-material/Edit";

//Services
import Swal from "sweetalert2";
import { manageErrors } from '../../../../hooks/manageErrors'
import AuthService from "../../../../services/auth.service";

export default class TableTrabajador extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",

      page: 1,
      count: 0,
      pageSize: 10,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
    this.retrieveTutorials();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  getRequestParams(searchTitle, page, pageSize) {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveTutorials() {
    const { searchTitle, page, pageSize } = this.state;
    const params = this.getRequestParams(searchTitle, page, pageSize);

    TrabajadorService.getTrabajadorContent(page) //params
      .then((response) => {
        const { data } = response.data;
        const { last_page } = response.data.meta;

        this.setState({
          tutorials: data,
          count: last_page,
        });
      })
      .catch((e) => {
        manageErrors(e);
      });
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial, index) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  render() {
    const {
      searchTitle,
      tutorials,
      currentTutorial,
      currentIndex,
      page,
      count,
      pageSize,
    } = this.state;

    const rows = [
      { id: 1, col1: "Hello", col2: "World" },
      { id: 2, col1: "DataGridPro", col2: "is Awesome" },
      { id: 3, col1: "MUI", col2: "is Amazing" },
    ];
    //
    //const columns = [
    //  "id",
    //  "nombre",
    //  "apodo",
    //  "iglesia",
    //  "referencia",
    //  "telefono",
    //  "tipo",
    //];

    const currentRole = AuthService.getCurrentRole();

    const columns_admin = [
      { headerClassName: 'header-table', flex:1, field: "id", headerName: "ID", width: 150 },
      { headerClassName: 'header-table', flex:1, field: "nombre", headerName: "Nombre", width: 300 },
      { headerClassName: 'header-table', flex:1, field: "email", headerName: "Email", width: 340 },
      { headerClassName: 'header-table', flex:1, field: "role", headerName: "Rol", width: 150 },
      {
        headerClassName: 'header-table', 
        flex:1,
        field: "active",
        headerName: "Activo",
        width: 150,
        renderCell: (params) => {
          const { row } = params;
          return (
            <React.Fragment>
              {row && row.activo === 0 && <span>INACTIVO</span>}
              {row && row.activo === 1 && <span>ACTIVO</span>}
            </React.Fragment>
          );
        },
      },
      {
        headerClassName: 'header-table', 
        flex:1,
        field: "acciones",
        headerName: "Acciones",
        width: 150,
        renderCell: (params) => {
          const { row } = params;
          return (
            <React.Fragment>
              <Link
                className="bas fa-plus-circle ml-3 icons-actions"
                fontSize="small"
                to={`/usuario/edit/${row.id}`}
              >
                <EditIcon />
              </Link>
            </React.Fragment>
          );
        },
      },
    ];

    const columns = [
      { headerClassName: 'header-table', flex:1, field: "id", headerName: "ID", width: 150 },
      { headerClassName: 'header-table', flex:1, field: "nombre", headerName: "Nombre", width: 150 },
      { headerClassName: 'header-table', flex:1, field: "email", headerName: "Email", width: 150 },
      { headerClassName: 'header-table', flex:1, field: "role", headerName: "Rol", width: 150 },
    ];

    return (
      <React.Fragment>
        {currentRole && (
          <div className="col-md-12">
            <Table
             data={tutorials}
             columns={currentRole === "ADMIN" ? columns_admin : columns}
             />


            <div className="mt-3 d-flex justify-content-end">
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
