import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import ReactDOM, { Link } from "react-router-dom";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";

import "date-fns";
import { Form, Field } from "react-final-form";

const FiltersProductos = ({ search, setSearch, status, setStatus }) => {
  return (
    <React.Fragment>
      <Form
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        onSubmit={() => {}}
        initialValues={{ tipo: status }}
        validate={() => {}}
        render={({ handleSubmit, submitting, values, form }) => {
          if (!window.setFormValue)
            window.setFormValue = form.mutators.setValue;
          return (
            <form onSubmit={handleSubmit} className="mt-4 mb-4" noValidate>
              <Grid container spacing={2}>
                {/* Row 1 */}
                <Grid item xs={12} sm={12} md={2} className="mt-3">
                  <Select
                    required={true}
                    name="tipo"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={"TODOS"}>TODOS</MenuItem>
                    <MenuItem value={"BODEGA"}>BODEGA</MenuItem>
                    <MenuItem value={"ALMACENAMIENTO"}>ALMACENAMIENTO</MenuItem>
                    <MenuItem value={"PROCESO"}>PROCESO</MenuItem>
                    <MenuItem value={"SEMI-TERMINADO"}>SEMI-TERMINADO</MenuItem>
                    <MenuItem value={"TERMINADO"}>TERMINADO</MenuItem>
                  </Select>
                </Grid>

                {/* Row 2 */}
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    name="busqueda"
                    label="Busqueda por codigo"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={search}
                    onChange={(e) => {
                      //window.setFormValue("busqueda", e.target.value);
                      setSearch(e.target.value);
                      //values.busqueda = e.target.value;
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} align={"right"}>
                  <Link to="/producto/create" className="btn btn-secondary">
                    Nuevo
                  </Link>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </React.Fragment>
  );
};

export default FiltersProductos;
