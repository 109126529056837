import React, { useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import ListAsignacion from "./ListAsignacion";
import FiltersAsignacion from "./Filters";

const ListaPuntuacion = () => {
  const dayjs = require("dayjs");
  const [value, setValue] = useState("1");
  const [catalogo, setCatalogo] = useState("TODOS");
  const [search, setSearch] = useState("");

  const [anio, setAnio] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <h5 className="ml-3 mt-3"><strong>Lista de Puntajes</strong></h5>

      <FiltersAsignacion anio={anio} setAnio={setAnio} className="" />

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="CABECILLAS" value="1" />
              <Tab label="INDIVIDUALES" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ListAsignacion
              catalogo={catalogo}
              search={search}
              value={value}
              anio={dayjs(anio).year()}
            />
          </TabPanel>
          <TabPanel value="2">
            <ListAsignacion
              catalogo={catalogo}
              search={search}
              value={value}
              anio={dayjs(anio).year()}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default ListaPuntuacion;
