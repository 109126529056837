import Swal from "sweetalert2";
import AuthService from "../services/auth.service";


export const manageErrors = (err) => {  
  if (err.response && err.response.status === 401) {
    AuthService.logout();
    window.location.reload();
  }
  else if (err.response.data && err.response.data.error) {
    Swal.fire("Error!", err.response.data.error, "error");
  }
}
