import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import Button from "@mui/material/Button";
//Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Box from "@mui/material/Box";
//import IconButton from "@mui/material/IconButton";
//import BorderColorIcon from "@mui/icons-material/BorderColor";

import ModalAsignacion from "./ModalAsignacion";
import ActionsAsignacion from "./Actions";
import DetalleRecibo from "./DetalleRecibo";

const dayjs = require("dayjs");

const columns = [
  {
    dataField: "codigo",
    text: "Codigo",
    headerStyle: (colum, colIndex) => {
      return { width: "80px", textAlign: "center", backgroundColor: "#e0e0e0" };
    },

    formatter: (cell, row) => {
      const { producto, cabecilla } = row;

      return <div>{producto.codigo}</div>;
    },
  },
  {
    dataField: "nombre",
    text: "Informacion",
    headerStyle: (colum, colIndex) => {
      return { textAlign: "center", backgroundColor: "#e0e0e0" };
    },
    formatter: (cell, row) => {
      const { producto, cabecilla } = row;

      return (
        <React.Fragment>
          <div>
            <span className="font-weight-bold">Nombre: </span>
            {producto.nombre}
          </div>
          <div>
            <span className="font-weight-bold">Catalogo: </span>
            {producto.catalogo}
          </div>
          <div>
            <span className="font-weight-bold">Estado: </span>
            {producto.estado === "PROCESO" ? (
              <span style={{ color: "green" }}>PROCESO</span>
            ) : producto.estado === "ALMACENAMIENTO" ? (
              <span style={{ color: "red" }}>ALMACENAMIENTO</span>
            ) : (
              producto.estado
            )}
          </div>
        </React.Fragment>
      );
    },
  },
  {
    dataField: "created_at",
    text: "Fecha",
    headerStyle: (colum, colIndex) => {
      return { width: "80px", textAlign: "center", backgroundColor: "#e0e0e0" };
    },
    formatter: (cell, row) => {
      return (
        <React.Fragment>
          <div>{dayjs(cell).format("DD/MM/YYYY")}</div>
        </React.Fragment>
      );
    },
  },
  {
    dataField: "cabecilla",
    text: "Cabecilla actual",
    headerStyle: (colum, colIndex) => {
      return {
        width: "200px",
        textAlign: "justify",
        backgroundColor: "#e0e0e0",
      };
    },
    formatter: (cell, row) => {
      let nombre = row?.cabecilla?.nombre;
      let telefono = row?.cabecilla?.telefono;

      const { producto, cabecilla } = row;
      return (
        <React.Fragment>
          <div>
              <div>
                {nombre ? nombre : '------------------'} 
                <br/>
                {telefono ? telefono : '------------------'}
              </div>
            </div>
        </React.Fragment>
      );
    },
  },
];

const columns_indvidual = [
  {
    dataField: "codigo",
    text: "Codigo",
    headerStyle: (colum, colIndex) => {
      return { width: "80px", textAlign: "center", backgroundColor: "#e0e0e0" };
    },

    formatter: (cell, row) => {
      const { producto, cabecilla } = row;

      return <div>{producto.codigo}</div>;
    },
  },
  {
    dataField: "nombre",
    text: "Informacion",
    headerStyle: (colum, colIndex) => {
      return { textAlign: "center", backgroundColor: "#e0e0e0" };
    },
    formatter: (cell, row) => {
      const { producto, cabecilla } = row;

      return (
        <React.Fragment>
          <div>
            <span className="font-weight-bold">Nombre: </span>
            {producto.nombre}
          </div>
          <div>
            <span className="font-weight-bold">Catalogo: </span>
            {producto.catalogo}
          </div>
          <div>
            <span className="font-weight-bold">Estado: </span>
            {producto.estado}
          </div>
        </React.Fragment>
      );
    },
  },
  {
    dataField: "created_at",
    text: "Fecha",
    headerStyle: (colum, colIndex) => {
      return { width: "80px", textAlign: "center", backgroundColor: "#e0e0e0" };
    },
    formatter: (cell, row) => {
      return (
        <React.Fragment>
          <div>{dayjs(cell).format("DD/MM/YYYY")}</div>
        </React.Fragment>
      );
    },
  },
];

const TablaAsignacion = ({ data = [], reset, value, catalogo = "" }) => {
  const expandRow = {
    renderer: (row) => (
      <div>
        <p className="font-weight-bold">Descripcion:</p>

        <span>{row.producto.descripcion}</span>

        <p align={"right"}>
          {row.asignacion.length == 1 &&
          row.asignacion[0].completado == true &&
          row.asignacion[0].tipo_bordado != "COMPLETO" ? (
            <ModalAsignacion reset={reset} data={row} catalogo={catalogo} />
          ) : (
            row.asignacion.length < 1 && (
              <ModalAsignacion reset={reset} data={row} catalogo={catalogo} />
            )
          )}
        </p>
        <table className="table table-bordered mt-3 mb-2">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Entrega</th>
              <th scope="col">Asignado</th>
              <th scope="col">Tipo</th>
              <th scope="col">Precio</th>
              <th scope="col">Aumento</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {row.asignacion.length > 0 &&
              row.asignacion.map(function (asignacion, idx) {
                return (
                  <tr key={idx}>
                    <td>{dayjs(asignacion.fecha).format("DD/MM/YYYY")}</td>
                    <td>
                      {asignacion && asignacion.trabajador && (
                        <React.Fragment>
                          <div>
                            <span className="font-weight-bold">Nombre: </span>
                            {asignacion.trabajador.nombre}
                          </div>
                          <div>
                            <span className="font-weight-bold">Telefono: </span>
                            {asignacion.trabajador.telefono}
                          </div>
                          <div>
                            <span className="font-weight-bold">Cabecilla: </span>
                            {asignacion?.trabajador?.cabecilla?.nombre}
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                    <td>{asignacion.tipo_bordado}</td>
                    <td>{"Q." + asignacion.precio}</td>
                    <td>{"Q." + asignacion.aumento}</td>
                    <td className="text-center">
                      {asignacion.completado == false ? (
                        <ActionsAsignacion
                          data={asignacion}
                          reset={reset}
                          producto={row.producto}
                          catalogo={catalogo}
                        />
                      ) : (
                        <DetalleRecibo
                          data={asignacion}
                          reset={reset}
                          producto={row.producto}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            {row.asignacion.length == 0 && (
              <tr>
                <td colSpan="5" className="text-center">
                  Sin Asignaciones
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <hr
          style={{
            color: "#000000",
            backgroundColor: "#000000",
            height: 5,
            borderColor: "#000000",
          }}
        />
      </div>
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <BootstrapTable
          keyField="id"
          data={data}
          className="tableExpand"
          columns={Number(value) === 2 || Number(value) === 4 ? columns_indvidual : columns}
          expandRow={expandRow}
          reset={reset}
        />
      </Box>
    </React.Fragment>
  );
};

export default TablaAsignacion;
