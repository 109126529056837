import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import ReactDOM, { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";

import { DatePicker } from "mui-rff";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import InputLabel from "@mui/material/InputLabel";
import { Form, Field } from "react-final-form";

const FiltersAsignacion = ({ search, setSearch, catalogo, setCatalogo }) => {
  //const [filter, setFilter] = useState("TODOS");


  return (
    <React.Fragment>
      <Form
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        onSubmit={() => {
        }}
        initialValues={{ tipo: catalogo }}
        validate={() => {}}
        render={({ handleSubmit, submitting, values, form }) => {
          if (!window.setFormValue)
            window.setFormValue = form.mutators.setValue;
          return (
            <form onSubmit={handleSubmit} className="mt-4 mb-4" noValidate>
              <Grid container spacing={2}>
                {/* Row 1 */}
                <Grid item xs={12} sm={12} md={2} className="mt-3">
                  <Select
                    required={true}
                    name="tipo"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      setCatalogo(e.target.value);
                    }}
                  >
                    <MenuItem value={"TODOS"}>TODOS</MenuItem>
                    <MenuItem value={"MINAS"}>MINAS</MenuItem>
                    <MenuItem value={"DIAMANTE"}>DIAMANTE</MenuItem>
                  </Select>
                </Grid>

                {/* Row 2 */}
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    name="busqueda"
                    label="Busqueda"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={search}
                    onChange={(e) => {
                      //window.setFormValue("busqueda", e.target.value);
                      setSearch(e.target.value);
                      //values.busqueda = e.target.value;
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} align={"right"}>
                  <Link
                    to="/asignacion_individual/create"
                    className="btn btn-secondary  mr-3  mt-1"
                  >
                    Asignacion individual
                  </Link>
                  <Link
                    to="/asignacion_cabecilla/create"
                    className="btn btn-secondary mr-3 mt-1"
                  >
                    Asignacion cabecilla
                  </Link>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </React.Fragment>
  );
};

export default FiltersAsignacion;
