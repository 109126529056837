import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { TextField } from "mui-rff";
import { Select } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";
import { useHistory, useParams } from "react-router-dom";

import { OnChange } from "react-final-form-listeners";

import { DatePicker } from "mui-rff";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import InputLabel from "@mui/material/InputLabel";
import { Form, Field } from "react-final-form";

import {
  TextMaskCustom,
  NumberFormatCustom,
  AsyncSelectField,
} from "../../../../../utils/renderField/renderField";

//Services
import TrabajadorService from "../../../Trabajador/trabajador.service";
import AsignacionService from "../../asignacion.service";

//Others
import { manageErrors } from "../../../../../hooks/manageErrors";
import Swal from "sweetalert2";

const dayjs = require("dayjs");
const UpdateFormAsignacion = ({
  data = [],
  handleClose,
  reset,
  catalogo = "",
  producto
}) => {
  let history = useHistory();
  let idCabecilla = data?.trabajador?.cabecilla
    ? data?.trabajador?.cabecilla?.id
    : null;

  const onSubmit = (dataValues) => {
    let values = { ...dataValues };
    values.trabajador_id = values.id_trabajador.id;
    //if(values.id_cabecilla) values.id_cabecilla = values.id_cabecilla.id;
    //values.salida_id = data.id;

    delete values.id_trabajador;
    delete values.trabajador;
    delete values.created_at;
    delete values.updated_at;

    AsignacionService.updateAsignacion(values, values.id)
      .then((response) => {
        if (response && response.status == 200) {
          Swal.fire("Guardado!", "", "success");
          //history.push("/asignacion");
          handleClose();
          reset();
        }
      })
      .catch((e) => {
        manageErrors(e);
        //Swal.fire("Guardado!", "", "success");
      });
  };

  //Search
  const getTrabajadoresCabecilla = (search) => {
    if (!idCabecilla) return [];
    return AsignacionService.getTrabajadorContentAsignacionCabecilla(
      1,
      search,
      true,
      idCabecilla
    )
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  //Search
  const getCabecillas = (search) => {
    return TrabajadorService.getTrabajadorContent(1, search, true)
      .then((response) => {
        const { data } = response.data;
        const filteredArray = data.filter(
          (item) => item.id !== Number(data.cabecilla_id)
        );
        return filteredArray;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  //Search
  const getTrabajadores = (search) => {
    return TrabajadorService.getTrabajadorContentIndividual(1, search, true)
      .then((response) => {
        const { data } = response.data;

        //const filteredArray = data.filter((item) => item.id !== Number(id));
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  const validate = (dataValues) => {
    let values = { ...dataValues };
    values.fecha = dayjs(values.fecha).format("YYYY-MM-DD");
    if (values.tipo_bordado == "NEGRO") {
      values.fecha = dayjs(values.created_at)
        .add(2, "month")
        .format("YYYY-MM-DD");
    } else if (values.tipo_bordado == "COMPLETO") {
      values.fecha = dayjs(values.created_at)
        .add(6, "month")
        .format("YYYY-MM-DD");
    }
    onSubmit(values);
  };


  return (
    <React.Fragment>
      <Form
        mutators={{
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        onSubmit={validate}
        initialValues={{
          ...data,
          id_cabecilla: data?.trabajador?.cabecilla,
          id_trabajador: data.trabajador,
        }}
        validate={() => {}}
        render={({ handleSubmit, submitting, values, form }) => {
          window.form = form;
          //if (!window.setFormValue)
          //  window.setFormValue = form.mutators.setValue;
          return (
            <form onSubmit={handleSubmit} className="mt-4" noValidate>
              <Grid container spacing={2}>
                {catalogo !== "INDIVIDUALES" && (
                  <React.Fragment>
                    {/* Row 2 */}
                    <Grid item xs={12} sm={12} md={6}>
                      <InputLabel htmlFor="cabecilla">Cabecilla *</InputLabel>
                      <Field
                        name="id_cabecilla"
                        label="Cabecilla"
                        component={AsyncSelectField}
                        loadOptions={getCabecillas}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        isSearchable={true}
                        isClearable={true}
                        labelKey="nombre"
                        valueKey="id"
                        //disabled={values.tipo === "TRABAJADOR" ? false : true}
                        //required={values.tipo === "TRABAJADOR" ? true : false}
                      />
                      <OnChange name="id_cabecilla">
                        {(value, previous) => {
                          // values.id_cabecilla = null;
                          // values.id_trabajador = null;
                          if (value) {
                            values.id_cabecilla = value;
                            idCabecilla = values?.id_cabecilla["id"];
                          } else {
                            idCabecilla = null;
                            //  //console.log('No existe :c', values.id_cabecilla);
                          }
                          window.form.mutators.setValue("id_trabajador", null);
                          //AsyncSelectField();
                        }}
                      </OnChange>
                    </Grid>
                    {values.id_cabecilla && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        key={`key_idCabRest${idCabecilla}+${values.id_trabajador}`}
                      >
                        <InputLabel
                          //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                          htmlFor="cabecilla"
                        >
                          Trabajador Individual *
                        </InputLabel>
                        <Field
                          key={
                            `key_idCab${values.id_trabajador}` ||
                            `key_idCab${values.id_cabecilla}`
                          }
                          name="id_trabajador"
                          label="Trabajador"
                          component={AsyncSelectField}
                          loadOptions={
                            idCabecilla ? getTrabajadoresCabecilla : null
                          }
                          getOptionLabel={(option) => option.nombre}
                          getOptionValue={(option) => option.id}
                          isSearchable={true}
                          isClearable={true}
                          labelKey="nombre"
                          valueKey="id"
                          required={true}
                          disabled={values.id_cabecilla ? false : true}
                        />
                      </Grid>
                    )}
                    {!values?.id_cabecilla && (
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel
                          //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                          htmlFor="cabecilla"
                        >
                          Trabajador Individual *
                        </InputLabel>
                        <Field
                          name="id_trabajador"
                          label="Trabajador"
                          component={AsyncSelectField}
                          loadOptions={null}
                          isSearchable={true}
                          isClearable={true}
                          labelKey="nombre"
                          valueKey="id"
                          required={true}
                          disabled={true}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                {/* Row 2 */}
                {catalogo === "INDIVIDUALES" && (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={6}>
                      <InputLabel
                        //className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root"
                        htmlFor="cabecilla"
                      >
                        Trabajador Individual *
                      </InputLabel>
                      <Field
                        name="id_trabajador"
                        label="Trabajador"
                        component={AsyncSelectField}
                        loadOptions={getTrabajadores}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        isSearchable={true}
                        isClearable={true}
                        labelKey="nombre"
                        valueKey="id"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}></Grid>
                  </React.Fragment>
                )}

                {/* Row 3  */}
                <Grid item xs={12} sm={12} md={6}>
                  {data &&
                  (data.tipo_bordado === "COMPLETO" ||
                    data.tipo_bordado === "NEGRO") ? (
                    <React.Fragment>
                      <Select
                        required={true}
                        name="tipo_bordado"
                        label="Tipo de bordado"
                        fullWidth
                        variant="standard"
                      >
                        <MenuItem value={"NEGRO"}>NEGRO</MenuItem>
                        <MenuItem value={"COMPLETO"}>COMPLETO</MenuItem>
                      </Select>
                    </React.Fragment>
                  ) : (
                    <TextField
                      name="tipo_bordado"
                      label="Tipo de bordado"
                      value={"COLOR"}
                      fullWidth
                      variant="standard"
                      required={true}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Grid>

                {/* Row 4 */}
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    name="precio"
                    label="Precio"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      readOnly: true,
                    }}
                    value={
                      data.precio
                        ? values.tipo_bordado === "COMPLETO"
                          ? Number(
                              producto.precio_negro +
                                producto.precio_color
                            )
                          : values.tipo_bordado === "NEGRO"
                          ? producto.precio_negro
                          : values.tipo_bordado === "COLOR"
                          ? producto.precio_color
                          : 0
                        : 0
                    }
                    fullWidth
                    variant="standard"
                    required={true}
                  />
                </Grid>

                {/* Row 5 */}
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    name="aumento"
                    label="Aumento"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    fullWidth
                    variant="standard"
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} textAlign="center">
                  <Button variant="contained" color="primary" type="submit">
                    Actualizar
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </React.Fragment>
  );
};

export default UpdateFormAsignacion;
