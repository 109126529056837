import React, { useState, useEffect, useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useStyles, theme } from "../../../Utils/ThemeStyle/Theme";

import { useHistory, useParams } from "react-router-dom";

//Const
import CONST from "../../../../consts/const";

//Services
import ProductoService from "../producto.service";

//Components
import CreateProductoForm from "./CreateProductoForm";
import FileUploader from "../../../Utils/FileUploader/FileUploader";

//Others
import LoaderContext from "../../../../context/ContextLoader";
import { manageErrors } from '../../../../hooks/manageErrors'
import Swal from "sweetalert2";

const CreateProducto = () => {
  //Params
  const { id } = useParams();

  let history = useHistory();

  //States
  const [imageSrc, setImageSrc] = useState(undefined);
  const [data, setData] = useState({});
  const [nextId, setNextId] = useState(undefined);
  const { loader, setLoader } = useContext(LoaderContext);

  const onFileChange = (e, file, key) => {
    file = file || e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file) {
      if (!file.type.match(pattern)) {
        alert("Formato inválido");
        return;
      }
      // this.setState({ loaded: false });
      reader.onload = (e) => {
        setImageSrc({ [key]: file });
      };
      reader.readAsDataURL(file);
    }
  };

  //GetNext
  const getId = () => {
    return ProductoService.getNext()
      .then((response) => {
        const { data } = response;
        if (data) setNextId(data);
        //const { last_page } = response.data.meta;
        //data.forEach((item) => {
        //  if (!_.find(listaSearchTrabajadores, { id: item.id }))
        //    listaSearchTrabajadores.push(item);
        //});
        return data;
      })
      .catch((e) => {
        manageErrors(e);
      });
  };

  //Submit
  const onSubmit = (dataForm) => {
    //Copy of data
    const body = { ...dataForm };

    delete body.codigo;

    setLoader(true);

    if (id) {
      if (imageSrc) {
        ProductoService.updateproductoWitPhotoContent(body, imageSrc.dpi, id)
          .then((response) => {
            if (response && response.status == 201) {
              Swal.fire("Guardado!", "", "success");
              history.push("/productos");
            }
          })
          .catch((e) => {
            manageErrors(e);
          }).finally(()=>{
            setLoader(false);
          });
      } else {
        ProductoService.updateProductContent(body, id)
          .then((response) => {
            if (response && response.status == 201) {
              Swal.fire("Guardado!", "", "success");
              history.push("/productos");
            }
          })
          .catch((e) => {
            manageErrors(e);
          }).finally(()=>{
            setLoader(false);
          });
      }
    } else {
      if (imageSrc) {
        ProductoService.createProductWithPhotoContent(body, imageSrc.dpi, id)
          .then((response) => {
            if (response && response.status == 201) {
              Swal.fire("Guardado!", "", "success");
              history.push("/productos");
            }
          })
          .catch((e) => {
            manageErrors(e);
          }).finally(()=>{
            setLoader(false);
          });
      } else {
        ProductoService.createProductContent(body)
          .then((response) => {
            if (response && response.status == 201) {
              Swal.fire("Guardado!", "", "success");
              history.push("/productos");
            }
          })
          .catch((e) => {
            manageErrors(e);
          }).finally(()=>{
            setLoader(false);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      ProductoService.getItem(id)
        .then((response) => {
          setData(response.data);
        })
        .catch((e) => {
          manageErrors(e);
        });
    } else {
      getId();
    }

    return () => {
      // removerItem();
    };
  }, []);

  return (
    <Container maxWidth="md">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Form
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          data={data}
          onSubmit={onSubmit}
          onFileChange={onFileChange}
          id={id}
          nextId={nextId}
        />
      </ThemeProvider>
    </Container>
  );
};

const Form = ({
  imageSrc,
  setImageSrc,
  data,
  onSubmit,
  onFileChange,
  id,
  nextId,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" align="center">
            Nuevo producto
          </Typography>

          <Grid item xs={12} sm={12} align="center" className="mb-4">
            <br />
            <FileUploader
              onFileChange={onFileChange}
              icono={"add-img"}
              frase={"trabajador."}
              clave={"dpi"}
              width="300px"
              height="300px"
              source={
                data.foto
                  ? CONST.STATIC_URL + "uploads/productos/" + data.foto
                  : null
              }
            />
          </Grid>
          <CreateProductoForm
            onSubmit={onSubmit}
            nextId={nextId}
            classes={classes}
            data={data}
          />
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default CreateProducto;
