import axios from "axios";
import authHeader from "../../../services/auth-header";
import CONST from "../../../consts/const";

const API_URL = CONST.API_URL; //"http://localhost:8080/api/test/";

class ReportesService {
    // Next
    getDescargar(informe, anio, tipo) {
      if(tipo){
        return axios.get(API_URL + "descargar?informe=" + informe + "&tipo=" + tipo + "&anio=" + anio , {
          headers: authHeader(),
          responseType: 'arraybuffer',
        });
      } else {
      return axios.get(API_URL + "descargar?informe=" + informe + "&anio=" + anio , {
        headers: authHeader(),
        responseType: 'arraybuffer',
      });

    }
    }
  
    /*get(page, catalogo, status = "", search = "") {
      return axios.get(
        API_URL +
          "producto?page=" +
          page +
          "&catalogo=" +
          catalogo +
          "&estado=" +
          status +
          "&search=" +
          search,
        {
          headers: authHeader(),
        }
      );
    }*/
  
  }
  
  export default new ReportesService();
  