import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

//Icons
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const TableCabecilla = ({ data = [], quitar }) => {
  const columns = [
    { field: "codigo", headerName: "Codigo", width: 150 },
    { field: "nombre", headerName: "Nombre", width: 150 },
    { field: "catalogo", headerName: "Catalogo", width: 150 },
    { field: "descripcion", headerName: "Descripcion" },
    {
      field: "acciones",
      headerName: "Acciones",
      width: 150,
      renderCell: (params) => {
        const { row } = params;
        return (
          <React.Fragment>
            <span
              type="button"
              className="bas fa-plus-circle ml-3 icons-actions"
              fontSize="small"
              onClick={() => {
                quitar(row);
              }}
            >
              <HighlightOffIcon />
            </span>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        hideFooter={true}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount={true}
        hideFooterPagination={true}
        dataSet={"Commodity"}
        rows={data}
        columns={columns}
      />
    </div>
  );
};

export default TableCabecilla;
